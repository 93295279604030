import React from "react";

export default function AbevButton({
  handleClick,
  label,
  disable,
  color,
  variant,
  loading,
  ...props
}) {

  return (
    <button
      variant={variant}
      color={color}
      disabled={disable}
      onClick={handleClick}
      className="btn"
    >
      {label}
    </button>
  );
}
