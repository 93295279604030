import React ,{useContext}from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { MyProvider } from "./context";

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <React.StrictMode>
      <MyProvider>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
    </MyProvider>
  </React.StrictMode> ,
  document.getElementById("root")
);

reportWebVitals();
