import {
    DATA_TYPE,
    ADJUST_BID_AMT_OPT,
    CURRENCY
} from "./constants";

const dutchFormRenderer = {
    "Item Name": {
        apiKey: "ItemName",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: false,
        tooltip: "Item name",
        isCurrency: false,
    },
    "Quantity": {
        apiKey: "quantity",
        type: DATA_TYPE.int,
        allowNull: false,
        isDropdown: false,
        tooltip: "Quantity of items",
        isCurrency: true,
    },
    "BFQ Spend": {
        apiKey: "bfq_spend",
        type: DATA_TYPE.int,
        allowNull: false,
        isDropdown: false,
        tooltip: "BFQ price of item before bidding",
        isCurrency: true,
    },
    // "BFQ Currency": {
    //     apiKey: "Bfq_Currency",
    //     type: DATA_TYPE.string,
    //     allowNull: true,
    //     isDropdown: true,
    //     tooltip: "BFQ Currency of item before bidding",
    //     options: CURRENCY,
    //     isCurrency: true,
    // },
    "Historic Spend": {
        apiKey: "historic_spend",
        type: DATA_TYPE.int,
        allowNull: true,
        isDropdown: false,
        tooltip: "Historic Spend of item before bidding",
        isCurrency: true,
    },
    // "Adjust Bid Amount": {
    //     apiKey: "Adjust_bid_amt",
    //     type: DATA_TYPE.string,
    //     allowNull: true,
    //     isDropdown: true,
    //     tooltip: "BFQ price of item before bidding",
    //     options: ADJUST_BID_AMT_OPT,
    //     isCurrency: false,
    // },

};

export default dutchFormRenderer;
