import React from "react";
import { gloabaltoastmessage } from "./ApiProvider/ApiProvider";
import copy from "../images/copy.svg";

// const useStyles = makeStyles((theme) => ({
//   copyButton: {
//     marginLeft: "4px",
//     padding: "0px",
//     borderWidth: "0px",
//     outline: "none",
//     weight: "24px",
//     height: "24px",
//   },
// }));

const AbevCopyToClipboard = ({ auctionKey, ...props }) => {
  // const classes = useStyles();

  const handleClick = () => {
    navigator.clipboard.writeText(auctionKey);
    // props.showToast("Copied");
    gloabaltoastmessage( "Copied", 5000, "success");
  };

  return (
    <>
     <button  className="border-0 bg-transparent mx-2" onClick={handleClick}>
    <img src={copy}  className="ml-1" width="20"/></button>
    </>
  );
};

export default AbevCopyToClipboard;
