import React, { createContext, useState } from 'react';
const MyContext = createContext();
const MyProvider = ({ children }) => {
  const [apiDataEnglish, setApiDataEnglish] = useState(null);
const [apiDataDutch, setApiDataDutch] = useState(null);
const [apiDataJapanese, setApiDataJapanese] = useState(null);
const [userInput, setuserInput] = useState(null);
const [uniqueAuctionKey_EN, setuniqueAuctionKey_EN] = useState(null);
const [uniqueAuctionKey_DU, setuniqueAuctionKey_DU] = useState(null);
const [uniqueAuctionKey_JA, setuniqueAuctionKey_JA] = useState(null);
  return (
<MyContext.Provider value={{ apiDataEnglish, setApiDataEnglish ,apiDataDutch,setApiDataDutch,apiDataJapanese, setApiDataJapanese,userInput, setuserInput,uniqueAuctionKey_EN, setuniqueAuctionKey_EN,uniqueAuctionKey_DU, setuniqueAuctionKey_DU,uniqueAuctionKey_JA, setuniqueAuctionKey_JA}}>
{/* {apiData || userInput ? children : null} */}
{children}
</MyContext.Provider>
  );
};
export {MyContext,MyProvider};