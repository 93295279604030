import {
    DATA_TYPE,
    CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW,
    ZONE,
    COMMODITY,
    CURRENCY
} from "./constants";

const dutchFormRenderer = {
    "Auction Name": {
        apiKey: "AuctionName",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: false,
        tooltip: "Auction name",
        isCurrency: false,
    },
    "Zone": {
        apiKey: "Zone",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: true,
        tooltip: "Zone where auction is conducted",
        options: ZONE,
        isCurrency: false,
    },
    "Commodity": {
        apiKey: "Commodity",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: true,
        tooltip: "Commodity for Auction",
        options: COMMODITY,
        isCurrency: false,
    },
    "Invited Suppliers": {
        apiKey: "Invited_suppliers",
        type: DATA_TYPE.int,
        allowNull: false,
        isDropdown: false,
        tooltip: "Total suppliers invited for the Auction",
        isCurrency: true,
    },
    "Sellers with no bid": {
        apiKey: "sellers_nobid",
        type: DATA_TYPE.int,
        allowNull: true,
        isDropdown: false,
        tooltip: "Sellers with no bid",
        isCurrency: true,
    },
    "Baseline Spend": {
        apiKey: "Baseline_spend",
        type: DATA_TYPE.int,
        allowNull: false,
        isDropdown: false,
        tooltip: "Baseline spend for all items combined",
        isCurrency: true,
    },
    "Baseline Spend Currency": {
        apiKey: "Baseline_currency",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: true,
        tooltip: "Baseline Currency for Auction",
        options: CURRENCY,
        isCurrency: true,
    },
    // "Historical Spend": {
    //     apiKey: "Historical_spend",
    //     type: DATA_TYPE.int,
    //     allowNull: true,
    //     isDropdown: false,
    //     tooltip: "Historical Spend for all Items Combined(Extended Price)",
    //     // text: "In case of missing Second-Best First Quote, please leave the field blank",
    //     // text1: "NOTE: Auction Edge provides better recommendations with Second-Best First Quote",
    //     isCurrency: true,
    // },
    // "Historical Spend Currency": {
    //     apiKey: "Historical_currency",
    //     type: DATA_TYPE.string,
    //     allowNull: true,
    //     isDropdown: true,
    //     tooltip: "Historical Currency for Auction",
    //     options: CURRENCY,
    //     isCurrency: true,
    // },
    "BFQ & Output Currency": {
        apiKey: "Output_currency",
        type: DATA_TYPE.string,
        allowNull: false,
        isDropdown: true,
        tooltip: "Output Currency for Auction",
        options: CURRENCY,
        isCurrency: false,
    },
};

export default dutchFormRenderer;
