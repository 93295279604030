import React , {useState,useContext } from "react";
import {MyContext} from '../../context';
import {
    HTTP_SERVICE_CALL,
    gloabaltoastmessage,
  } from "../../components/ApiProvider/ApiProvider";
import { useHistory } from "react-router-dom";
import * as Constants from "../../Constants";
import AbevButton from "../../components/AbevButton";
import AbevDropdown from "../../components/Dropdown/Dropdown";
import AbevInput from "../../components/AbevInput";
import formTemplate from "../../data/inputForm.template";
import { DATA_TYPE } from "../../data/constants";
import * as shortUUID from "short-uuid";
import Spinner from "../../components/Spinner/Spinner";
import UserAccessModal from "../../components/UserAccessModal/UserAccessModal";
const EnglishAuctionInput= (props) => {
  let history = useHistory();
  const { setApiDataEnglish,setuserInput,setuniqueAuctionKey_EN} = useContext(MyContext);
    // const [errorMessage, seterrorMessage] = useState("");
  const [errorApiEndPoint, seterrorApiEndPoint] = useState("");
const [AuctionName,setAuctionName]=useState("");
const [Zone,setZone]=useState("");
const [Invited_suppliers,setInvited_suppliers]=useState(0);
const [Sellers_without_initial_bid,setSellers_without_initial_bid]=useState(0);
const [Total_items,setTotal_items]=useState(0);
const [Baseline_spend,setBaseline_spend]=useState(0);
const [Currency,setCurrency]=useState("");
const [Commodity,setCommodity]=useState("");
const [Bfq,setBfq]=useState(0);
const [Second_Bfq,setSecond_Bfq]=useState(0);
const [apiInProgress,setapiInProgress]=useState(false);
const [viewAccess, setViewAccess] = useState(true);
const [errorMessage,seterrorMessage]=useState({
  Zone: "",
  AuctionName: "",
  Invited_suppliers: "",
  Sellers_without_initial_bid: "",
  Bfq: "",
  Second_Bfq: "",
  Baseline_spend: "",
  Total_items: "",
  Commodity: "",
  Currency: "",
  // Start_time: "",
});
  const canSubmit = () =>
    AuctionName &&
   Zone &&
   Invited_suppliers &&
   Sellers_without_initial_bid &&
   Total_items &&
   Baseline_spend &&
   Currency &&
  Commodity &&
    // this.state.Start_time &&
    !checkErrorMessage();

    const checkErrorMessage = () => {
    // if (Second_Bfq == 0) {
    //   setSecond_Bfq(-1);
    // }

    // if (Bfq == 0) {
    //  setBfq(-1);
    // }
       if (
      !errorMessage["Zone"] &&
      !errorMessage["Commodity"] &&
      !errorMessage["Currency"] &&
      !errorMessage["AuctionName"] &&
      !errorMessage["Invited_suppliers"] &&
      !errorMessage["Sellers_without_initial_bid"] &&
      !errorMessage["Bfq"] &&
      !errorMessage["Second_Bfq"] &&
      !errorMessage["Baseline_spend"] &&
      !errorMessage["Total_items"]
    ) {
      return false;
    } else return true;
  };
  // export example
  const handleSubmit = async() => {
    let key = shortUUID.generate();
    let auctionName = AuctionName + "-" + key;
    setuniqueAuctionKey_EN(auctionName);
    setapiInProgress(true);
    let params= {
      Zone:Zone,
      ["Invited Suppliers"]:Invited_suppliers,
      "Sellers Without Initial Bid":Sellers_without_initial_bid,
      "Total Items":Total_items,
      "Commodity":Commodity,
      "Currency":Currency,
      "Best First Quote":(Bfq == 0)?-1:Bfq,
      "Second Best First Quote":(Second_Bfq == 0)?-1:Second_Bfq,
      "Baseline Spend":Baseline_spend,
      "Start time":new Date().toLocaleString(),
      "auctionType": "English",
      "Username":props.user,
      "auction_name":AuctionName,
      // "hashValue": key,
      "uniqueAuctionKey": auctionName,
    };
   // this.state.BiddingTime = "Enter Bidding time on Ariba";
   try {
    let access = await HTTP_SERVICE_CALL(
      Constants.backendUrl + "get-azure-ml-data/english",
      "POST",
     
     params, "",
    );
    setApiDataEnglish(access?.data);
    setuserInput(params);
    history.push("/recommendation");
    if (access && access.status === 200) {
      setApiDataEnglish(access?.data);
      setapiInProgress(false);
      history.push("/recommendation");
    } else {
      setapiInProgress(false);
      gloabaltoastmessage( "An Error Occured, Please try after sometime", 5000, "error");
      seterrorMessage(access.message);
    }

} catch (error) {
  setapiInProgress(false);
  gloabaltoastmessage(error?.message, 5000, "error");
    seterrorMessage(error?.message);
    seterrorApiEndPoint(error?.config?.url);
  }

    // props.getRecommendation(
    //   {
    //     ...this.state,
    //     auctionType: "English",
    //     hashValue: key,
    //     uniqueAuctionKey: auctionName,
    //   },
    //   (err) => {
    //     if (err) {
    //       this.setState({ apiInProgress: false });
    //       return this.props.showToast(
    //         "An Error Occured, Please try after sometime",
    //         "error"
    //       );
    //     }
    //     this.setState({ apiInProgress: false });
    //     this.props.history.push("/recommendation");
    //   }
    // );
  };

  const handleSelect = (name) => (e) => {
    if(name== "Zone")
    setZone(e.value);
    if(name== "Commodity")
    setCommodity(e.value);
    if(name== "Currency")
    setCurrency(e.value);
   // this.setState((prevState) => (prevState[name] = e.value));
  };

  const handleChange = (name) => (e) => {
   // this.setState({ [name]: e.target.value });
   if(name== "Baseline_spend" || name== "Bfq" || name== "Second_Bfq"){
    if(name== "Baseline_spend")
    setBaseline_spend(parseFloat(e.target.value));
    if(name== "Bfq")
    setBfq(parseFloat(e.target.value));
    if(name== "Second_Bfq")
    setSecond_Bfq(parseFloat(e.target.value));
   validChanges(name, parseFloat( e.target.value,10));
   }
   else{
    if(name== "AuctionName")
    setAuctionName(e.target.value);
    if(name== "Invited_suppliers")
    setInvited_suppliers(e.target.value);
    if(name== "Sellers_without_initial_bid")
    setSellers_without_initial_bid(e.target.value);
    if(name== "Total_items")
    setTotal_items(e.target.value);
    validChanges(name, parseInt(e.target.value, 10));
   }
  //  if(name== "Baseline_spend")
  //  setBaseline_spend(e.target.value);
  //  if(name== "Bfq")
  //  setBfq(e.target.value);
  //  if(name== "Second_Bfq")
  //  setSecond_Bfq(e.target.value);
  // validChanges(name, parseInt(e.target.value, 10));
  };

  // const  handleDateChange = (name) => (d) => {
  //   this.setState((prevState) => (prevState[name] = d));
  //   this.validChanges(name, d);
  // };

  const validChanges=(name, value) =>{
    let prevError = { ...errorMessage };
    const sw_inital_bid_Value = parseInt(
      Sellers_without_initial_bid,
      10
    );
    const Bestfq = Bfq;
    switch (name) {
      case "Invited_suppliers":
        if (value < 1) {
          prevError[name] = "Value cannot be -ve or Zero";
          return seterrorMessage(prevError);
        }
        if (value < sw_inital_bid_Value) {
          prevError[name] =
            "Should be greater than Sellers without initial bid";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
          prevError["Sellers_without_initial_bid"] = "";
            return seterrorMessage(prevError);
        }

      case "Sellers_without_initial_bid":
        prevError = { ...errorMessage };

        if (value >= Invited_suppliers) {
          prevError[name] = "Should be less than Invited Suppliers";
            return seterrorMessage(prevError);
        } else if (value < 0) {
          prevError[name] = "Value should not be -ve";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
            return seterrorMessage(prevError);
        }

      case "Total_items":
        prevError = { ...errorMessage };
        if (value < 1) {
          prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
            return seterrorMessage(prevError);
        }

      case "Bfq":
        console.log(value)
        prevError = { ...errorMessage };
        if (value > Second_Bfq && Second_Bfq != 0) {
          prevError[name] = "BFQ should be less than Second BFQ";
            return seterrorMessage(prevError);
        } else if (value <= 0) {
          prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
            return seterrorMessage(prevError);
        }
      case "Second_Bfq":
        console.log(value)
        prevError = { ...errorMessage };

        // if(this.state.Bfq==0 ||this.state.Bfq=="" && value!=0 && value!=NaN)
        // {
        //   prevError[name] = "Sec BFQ should be greater than BFQ";
        //     return seterrorMessage(prevError);
        // }

        if (value < Bfq && value > 0) {
          prevError[name] = "Second BFQ should be greater than BFQ";
            return seterrorMessage(prevError);
        } else if (Bestfq == "" && value > 0) {
          prevError[name] = "Second BFQ should be greater than BFQ";
            return seterrorMessage(prevError);
        } else if (value <= 0) {
          prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
            return seterrorMessage(prevError);
        }

      case "Baseline_spend":
        prevError = { ...errorMessage };
        console.log(value)
        if (value <= 0) {
          prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
        } else {
          prevError[name] = "";
            return seterrorMessage(prevError);
        }

      // case "Start_time":
      //   prevError = { ...this.state.errorMessage };
      //   if (value) {
      //     prevError[name] = "";
      //       return seterrorMessage(prevError);
      //   }
      //   else {
      //     prevError[name] = "Required";
      //       return seterrorMessage(prevError);
      //   }
      default:
        return true;
    }
  }

  const renderForm = (fieldKey, indx) => {
   // Add this line
    let fieldDetails = formTemplate[fieldKey];
   if (fieldDetails.type === DATA_TYPE.string && !fieldDetails.isDropdown) {
      return (
        <AbevInput
          key={indx}
          required={!fieldDetails.allowNull}
          label={fieldKey}
          info={fieldDetails.tooltip}
          handleChange={handleChange(fieldDetails.apiKey)}
        helperText={errorMessage[fieldDetails.apiKey]}
        />
      );
    }
    if (
      fieldDetails.type === DATA_TYPE.int &&
      !fieldDetails.isDropdown &&
      ["Best First Quote", "Second Best First Quote"].includes(fieldKey)
    ) {
      return (
        <AbevInput
          key={indx}
          label={fieldKey}
          info={fieldDetails.tooltip}
          type="number"
          handleChange={handleChange(fieldDetails.apiKey)}
          helperText={errorMessage[fieldDetails.apiKey]}
          text={fieldDetails.text}
          text1={fieldDetails.text1}
        />
      );
    }
    if (
      fieldDetails.type === DATA_TYPE.int &&
      !fieldDetails.isDropdown &&
      fieldKey != "Best First Quote" &&
      fieldKey != "Second Best First Quote"
    ) {
      return (
        <AbevInput
          key={indx}
          required={!fieldDetails.allowNull}
          label={fieldKey}
          info={fieldDetails.tooltip}
          type="number"
          handleChange={handleChange(fieldDetails.apiKey)}
          helperText={errorMessage[fieldDetails.apiKey]}
        />
      );
    }
    if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown) {
      return (
        <AbevDropdown
          key={indx}
          required={!fieldDetails.allowNull}
          label={fieldKey}
          info={fieldDetails.tooltip}
          options={fieldDetails.options}
          // defaultValue={{
          //   label: this.state[fieldDetails.apiKey],
          //   value: this.state[fieldDetails.apiKey],
          // }}
          handleChange={handleSelect(fieldDetails.apiKey)}
        />
      );
    }
    if (fieldDetails.type === DATA_TYPE.int && fieldDetails.isDropdown) {
      return (
        <AbevDropdown
          key={indx}
          required={fieldDetails.allowNull}
          label={fieldKey}
          info={fieldDetails.tooltip}
          options={fieldDetails.options}
          // defaultValue={{
          //   label: this.state[fieldDetails.apiKey],
          //   value: this.state[fieldDetails.apiKey],
          // }}
          handleChange={handleSelect(fieldDetails.apiKey)}
        />
      );
    }
  };

 
    return (
      <>
    
        <div className="main">
          <div className="container">
            <h4 className="py-2 y_text">English Reverse Recommendation</h4>
            {Object.keys(formTemplate).map(renderForm)}
            <AbevButton
              color="primary"
              variant="contained"
              label="Submit"
              handleClick={handleSubmit}
             disable={!canSubmit()}
            />
          </div>
        </div>
      
         {apiInProgress ? <Spinner /> : ""}
      </>
    );
  


};

export default EnglishAuctionInput;
