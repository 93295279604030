import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import HomePage from "./pages/Homepage/Homepage";
import { useIsAuthenticated } from "@azure/msal-react";
import history from "./history";
import EnglishAuctionInput from "./pages/EnglishAuctionInput/EnglishAuctionInput";
import DutchReverseAuctionInput from "./pages/DutchReverseAuctionInput/DutchReverseAuctionInput";
import JapaneseReverseAuctionInput from "./pages/JapaneseReverseAuctionInput/JapaneseReverseAuctionInput";
import Recommendation from "./pages/Recommendation/Recommendation";
// import { callMsGraph } from "./graph";
// import { loginRequest } from "./authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import React, { useState } from "react";

const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const name = accounts[0] && accounts[0].name;
  const user = accounts[0] && accounts[0].username;

  return (
    <>
      <Router  history={history}>
        {/* {(accounts[0]) ?    <Header name={accounts[0].name}/> :<Header/>} */}
        <Header name={name} />
        <div className="content">
          <Switch>
            <Route exact path="/login"  component={() => (
                <Login
                  user={user}
                  name={name}
                />
              )} />
            <Route exact path="/" component={() => (
                <HomePage
                  user={user}
                  name={name}
                />
              )} />
            <Route exact path="/user/English" component={() => (
                <EnglishAuctionInput
                  user={user}
                  name={name}
                />
              )} />
            <Route exact path="/recommendation" component={() => (
                <Recommendation
                  user={user}
                  name={name}
                />
              )} />
              <Route exact path="/user/Dutch" component={() => (
                <DutchReverseAuctionInput
                  user={user}
                  name={name}
                />
              )} />
              <Route exact path="/user/Japanese" component={() => (
                <JapaneseReverseAuctionInput
                  user={user}
                  name={name}
                />
              )} /> 
            <Redirect to="/" user={user} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
};
function App(props) {
  const isAuthenticated = useIsAuthenticated();
  
  return (
    <div className="App">
      {isAuthenticated > 0 ? (
        <AuthenticatedTemplate>
          <ProfileContent />
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      )}
    </div>
  );
}

export default App;
