import React from "react";
import { TailSpin } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Spinner = () => {
  return (
    <div className="spinner">
      <div className="loadingMessage y_text">
            Fetching your recommendations
          </div>
      <TailSpin color="yellow" height={300} width={300} />
    </div>
  );
};
export default Spinner;
