import React from "react";

function Footer(props) {
  return (
    <footer className="footer text-secondary">
      <p>© 2023 Anheuser-Busch InBev All rights reserved.</p>
    </footer>
  );
}

export default Footer;
