import React from "react";
import "./Title.css";
import Info from "../../images/info-circle.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function Title({ name, text }) {
  const renderTooltip = (props) => <Tooltip {...props}>{text}</Tooltip>;

  return (
    <div className="title mx-auto text-left">
      {/* <h3 className="d-inline">{name}</h3> */}

      <OverlayTrigger placement="right" overlay={renderTooltip}>
        <img className="pl-3" src={Info} alt="Info" />
      </OverlayTrigger>
    </div>
  );
}

export default Title;
