import "./Header.css";
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "../../images/logo.png";
import { NavLink,useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
function handleLogout(instance) {
  instance.logoutPopup().catch((e) => {
    console.error(e);
  });
}

function Header(props) {
  const { instance } = useMsal();
  const location = useLocation();
  return (
    <div className="Header">
        <Navbar className="mx-auto" expand="lg">
        <Navbar.Brand href="/homepage">
          <img className="logo-width" src={Logo} alt="ABInBev1 Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav className="ml-auto">
            <Nav className="mr-auto avantt-sb">
              <Nav.Link  as={NavLink} to="/">
                Home
              </Nav.Link>
             
              <a className="dashboard"  href="https://app.powerbi.com/groups/me/apps/33e6b138-debe-4431-b2d5-3d58c44f0d89/reports/2e36d95e-47a9-441b-9140-693a2423ea32/ReportSection4ffc46188c965ece5da8?ctid=cef04b19-7776-4a94-b89b-375c77a8f936" target="_blank">Dashboard</a>
              {(location?.pathname == "/homepage" || location?.pathname == "/" ) && (
               <Nav.Link href="#talktoUs">Need Help</Nav.Link>
              )}
            </Nav>
            <Navbar.Text
              onClick={() => handleLogout(instance)}
              className="avantt-sb"
              id="logoutClick"
            >
              Logout
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
       
    </div>
  );
}

export default Header;
