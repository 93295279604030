import React from "react";
import "./ModelTable.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
function ModelTable(props) {
  {{console.log("table-responsive mb-2 p-4",props)}}
  
  return (
  
    <BootstrapTable
      bootstrap4
      wrapperClasses="table-responsive mb-2 p-4"
      rowClasses="text-nowrap"
      hover
      keyField={props.keyField}
      data={props.data.length>0 ? props.data[0]:props.data}
      columns={props.columns}
      // pagination={props.pagination}
      // filter={props.filter}
      sort={props.sort}
      noDataIndication={() => (
        <h6 className="p-5 avantt-sb">
          {props.text}
        </h6>
      )}
    />
  );
}
export default ModelTable;
