import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Homepage.css";
import AE from  "../../images/Handbook.pdf";
// import { useGlobalContext } from "../../context";
import AOS from "aos";
import "aos/dist/aos.css";
const Homepage = (props) => {
  useEffect(() => {
    AOS.init();
    localStorage.clear();
  }, []);
   const history = useHistory();
  const onClickAuctionSelect = (page, index) => {
    switch (page) {
      case "English":
        history.push(`/user/${page}`);
        break;
      case "Dutch":
        history.push(`/user/${page}`);
        break;
      case "Japanese":
        history.push(`/user/${page}`);
        break;
      default:
        break;
    }
  };
return (
  <div>
    {/* <Navbar auctionType={""}></Navbar> */}
    <div className="world d-flex">
        <div>
          <p className="text-white reveal-text avantt-b">
            <span> Welcome {props.name}  </span>
            {/* <h1>Auction Edge</h1> */}
          </p>
          <p className="w-75 text-white pb-5 avantt-l">
          This tool is helping e-Auction specialists in generating optimal strategy to configure e-Auctions on SAP ARIBA.
          </p>
        </div>
      </div>
      
    <div className="justify-content-center business">
        {/* <h3 className="pt-5 font-weight-bold color-base">Business Features</h3> */}
        <div className="cards-section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="box-container">
                  <div
                    className="box-item"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="flip-box">
                      <div className="flip-box-front one text-center">
                        <div className="color-white top-flip-inner">
                         
                          <h3 className="flip-box-header avantt-b">
                            English
                          </h3>
                          <hr />
                          <p>
                          Multiple sellers race in a limited time duration to push bid prices down to offer the most competitive price to buyer. The auction ends with the lowest bid as winner.
                          </p>
                        </div>
                      </div>
                      <div className="flip-box-back one text-center">
                        <div className="inner color-white inner-after-flip avantt-sb">
                          <hr />
                          <p>Key benefits:</p>
                          <ol><li>Multiple sellers</li>
                          <li>Competitive quotes</li>
                          <li>High spend</li></ol>
                          <button className="btn_region" onClick={() => onClickAuctionSelect("English")}>English</button>
       
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="box-item"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="flip-box">
                      <div className="flip-box-front two text-center">
                        <div className="color-white top-flip-inner">
                          
                          <h3 className="flip-box-header avantt-b">
                            Dutch
                          </h3>
                          <hr />
                          <p>The buyer sets an initial low price and increases it by a fixed amount and at fixed intervals until a seller agrees to it. This elimination-style auction ends with the first seller's bid acceptance.</p>
                        </div>
                      </div>
                      <div className="flip-box-back two text-center">
                        <div className="inner color-white inner-after-flip avantt-sb">
                          <hr />
                          <p>Key benefits:</p>
                          <ol><li>Few sellers</li>
                          <li>Quick elimination</li>
                         </ol>
                         <button className="btn_region" onClick={() => onClickAuctionSelect("Dutch")}>Dutch</button>
       
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="box-item"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="flip-box">
                      <div className="flip-box-front three text-center filter-">
                        <div className="color-white top-flip-inner">
                         
                          <h3 className="flip-box-header avantt-b">
                            Japanese
                          </h3>
                          <hr />
                          <p>The buyer sets an initial high price and decreases it by a fixed amount and at fixed intervals, letting sellers accept each round’s price. Failure to bid eliminates the seller, and the auction ends when only one seller remains or price reaches its lowest possible value.</p>
                        </div>
                      </div>
                      <div className="flip-box-back three text-center">
                        <div className="inner color-white inner-after-flip avantt-sb">
                          <hr />
                          <p>Key benefits:</p>
                          <ol><li>Few sellers</li>
                          <li>Low spend</li>
                         </ol>
                         <button className="btn_region" onClick={() => onClickAuctionSelect("Japanese")}>Japanese</button>
       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="talktoUs" className="pb-3"><h3>Contact Us</h3>
      <p>Get in touch & let us know how we can help</p>
      <p>If you have any technical challenges , Please raise a ticket on Service Now portal from  <a href="https://abinbevww.service-now.com/abiex?auth=2"  target="_blank">here.</a> </p>
    
      <p>You can also refer to Service Now Portal Handbook <a href={AE} download="AuctionEdge-ServiceNow-HandBook"> Download</a></p></div>
    {/* <AbevFooter /> */}
  </div>
);
};
export default Homepage;
