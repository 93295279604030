import React, { Component, useState,useContext } from "react";
import {MyContext} from '../../context';
import {
  HTTP_SERVICE_CALL,
  gloabaltoastmessage,
} from "../../components/ApiProvider/ApiProvider";
import axios from "axios";
import formTemplate from "../../data/outputForm.template";
import { DATA_TYPE, LANGUAGE_CONST, RECOMMENDATION_HEADERS } from "../../data/constants";
import { DROPDOWN_LANGUAGE_TRANSLATIONS_OBJECT } from "../../data/translations";
import AbevDropdown from "../../components/Dropdown/Dropdown";
import AbevInput from "../../components/AbevInput";
import AbevButton from "../../components/AbevButton";
import * as Constants from "../../Constants";
import {Trigger_Columns_Table} from "../../Constants";
import UserInputCard from "../../components/UserInputCard";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import AbevCopyToClipboard from "../../components/AbevCopyToClipboard";
const Recommendation= () => {
  
  let history = useHistory();
  const { apiDataEnglish ,apiDataDutch,apiDataJapanese,userInput,uniqueAuctionKey_DU,uniqueAuctionKey_EN ,uniqueAuctionKey_JA  } = useContext(MyContext);
  const [data,setdata]=useState([]);
  const [backupData,setbackupData]=useState([]);
  const [Item,setItem]=useState([]);
  const [stateData,setstateData]=useState([]);
  const [lang,setlang]=useState("");
  const [Auction_Name1,setAuction_Name]=useState("");
  const [languageDefaultValue,setlanguageDefaultValue]=useState({});
  const [apiInProgress,setapiInProgress]=useState(false);
  const [modelRecommendation,setmodelRecommendation]=useState([]);
  const [openModal,setopenModal]=useState(false);
    const [showhideModal, setShowHideModal] = useState(true);
  const [bidding,setbidding]=useState("");
  const [errorMessage,seterrorMessage]=useState({
    "Capacity type for the event": "",
    "Bidding format for the event": "",
    "Specify how lot bidding will begin and end": "",
    "Enable preview period before bidding opens": "",
    "Specify how participants view market information": "",
  });
  const [viewAccess, setViewAccess] = useState(true);
  const [newLoad,setnewLoad]=useState(false);
  const [rank,setrank]=useState("lrank");
  const [overtime,setovertime]=useState(true);
  const [review,setreview]=useState(true);
  const [final_data,setfinal_data]=useState([]);
  const [period,setperiod]=useState(true);
 const [cols,setcols] = [
    { key: "ID", name: "ID" },
    { key: "Value", name: "Value" },
  ];
  let Auction_Name;
  let rule_name="Rules";
  async function downloadtablelink() {
    
    let toExport = data
    .map((payloadObj) => {
      if (payloadObj.ID === "label") {
        return null;
      }

      let fieldDetails = formTemplate[payloadObj.ID];
      if (!fieldDetails) {
        return null;
      }

      return payloadObj;
    })
    .filter((e) => e)
    .map((e, indx) => {
      let fieldDetails = formTemplate[e.ID];
      // if (e.Value && fieldDetails.type === DATA_TYPE.dateTime) {
      //   // +2 becasue the 1st cell is header and cell start with 1 not 0. hence need to offset 2
      //   fieldIndicesWithDate.push(indx + 2);
      // }
      return e;
    });

    const changePayloadLanguage = (exportData, lang) => {

      let payloadLanguageProperty = {
        es: { ID: "ID", Value: "Valor" },
        pt: { ID: "Código", Value: "Valor" },
      };
          switch (lang) {
      case "en":
        rule_name="Rules";
        break;
      case "es":
        rule_name="Reglas";
        break;
      case "pt":
        rule_name="Regras";
        break;
    }
      if (lang !== "en") {
        let newPayload = [];
        exportData.map((data) => {
          let fieldDetails = formTemplate[data.ID];
          let labelName = lang + "Label";
          let newData = {};
          let timeValue;
          newData[payloadLanguageProperty[lang]["ID"]] = fieldDetails[labelName];
          if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown) {
            newData[payloadLanguageProperty[lang]["Value"]] =
              DROPDOWN_LANGUAGE_TRANSLATIONS_OBJECT[lang].find(
  
                (translationData) => translationData.value === data["Value"]
              )["label"];
          } else if (
            data.ID === "Running time for the first lot" ||
            data.ID === "Bidding period"|| data.ID === "Time between lot closing" || data.ID === "Bid adjustment interval"
          ) {
            if (data.Value && data.Value.toString().split(" ")[1] === "Minutes") {
              timeValue = parseInt(data.Value.toString().split(" ")[0]);
            }
            newData[payloadLanguageProperty[lang]["Value"]] = timeValue
              ? timeValue + " Minutos"
              : data["Value"] + " Minutos";
          } else newData[payloadLanguageProperty[lang]["Value"]] = data["Value"];
          newPayload.push(newData);
        });
        // setcols([
        //   {
        //     key: payloadLanguageProperty[lang]["ID"],
        //     name: payloadLanguageProperty[lang]["ID"],
        //   },
        //   {
        //     key: payloadLanguageProperty[lang]["Value"],
        //     name: payloadLanguageProperty[lang]["Value"],
        //   },
        // ]);
        console.log("Newpayload",newPayload)
        setnewLoad(newPayload);
        return newPayload;
      } else {
        // setcols([
        //   { key: "ID", name: "ID" },
        //   { key: "Value", name: "Value" },
        // ]);
        let newPayload = [];
        exportData.map((data) => {
          let fieldDetails = formTemplate[data.ID];
          let newData = {};
          let timeValue;
          if (
            data.ID === "Running time for the first lot" ||
            data.ID === "Bidding period" || data.ID === "Time between lot closing"
            || data.ID === "Bid adjustment interval"
          ) {
            if (data.Value && data.Value.toString().split(" ")[1] === "Minutes") {
              timeValue = parseInt(data.Value.toString().split(" ")[0]);
            }
            newData.ID = data.ID;
            newData.Value = timeValue
              ? timeValue + " Minutes"
              : data.Value + " Minutes";
          } else {
         
            newData.ID = data.ID;
            newData.Value = data.Value;
          }
          newPayload.push(newData);
        });
        console.log(newPayload)
        setnewLoad(newPayload);
        return newPayload;
      }
    };
 let x=changePayloadLanguage(toExport, lang);
 
  // console.log(changePayloadLanguage())
    if(userInput.auctionType == "English"){
      var params={"data": {
        "auction_name": Auction_Name1,
        [rule_name]:x,
      }
  };
    }
    else{
      var params={"data": {
        "auction_name": Auction_Name1,
        [rule_name]:x,
  "items":Item,
      }
  };
    }
   console.log(params)
    try {
          axios({
            url:  Constants.backendUrl + "export-data",
            method: "POST",
            data: params,
            headers: { "Content-Type": "application/json" },
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${Auction_Name1}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        });
   
      // if (access && access.status === 200) {
      //   console.log(access.data[0])
       
      // }else {
      //   setapiInProgress(false);
      //   gloabaltoastmessage( "An Error Occured, Please try after sometime", 5000, "error");
      //   seterrorMessage(access.message);
      // }
  
  } catch (error) {
    setapiInProgress(false);
    gloabaltoastmessage(error?.message, 5000, "error");
      seterrorMessage(error?.message);
      // seterrorApiEndPoint(error?.config?.url);
    }
  }
  useEffect(()=>
   {
    if (!userInput) {
      return history.push("/");
    }
    console.log(apiDataDutch,userInput);

    let stateData;let Item; 
    if(userInput.auctionType == "English"){
      stateData =apiDataEnglish[0];
      setstateData(apiDataEnglish[0]);
      setAuction_Name(uniqueAuctionKey_EN);
      console.log(" cjeck met condition3",apiDataEnglish[0]);
      if(apiDataEnglish[0]==undefined){
        console.log("met condition3",apiDataEnglish[0]);
        setViewAccess(false);
      }
    }
    if(userInput.auctionType == "Dutch"){
      stateData =apiDataDutch[0].rules;
      setstateData(apiDataDutch[0]?.rules);
      Item =apiDataDutch[0].items;
      setItem(apiDataDutch[0].items)
      Auction_Name=apiDataDutch[0].auction_name;
      setAuction_Name(uniqueAuctionKey_DU);
      console.log(stateData)
   }
   if(userInput.auctionType == "Japanese"){
      stateData =apiDataJapanese[0].rules;
      setstateData(apiDataJapanese[0]?.rules);
      Item =apiDataJapanese[0].items;
      setItem(apiDataJapanese[0].items)
      Auction_Name=apiDataJapanese[0].auction_name;
      setAuction_Name(uniqueAuctionKey_JA);
      console.log(stateData)
   }
    if (userInput.AuctionType === "Dutch" || userInput.AuctionType === "Japanese") {
      let JapRec = []
      let JapObj = {}
      let Index = stateData['index']
      let Data = stateData["data"]
      for (let i = 0; i < Index.length; i++) {
        let indx = Index[i]
        let data = Data[i][0]
        JapRec.push(JapObj[indx] = data)
      }

      stateData = JapRec
    }
    for (let i = 0; i < stateData?.length; i++) {
      if (stateData[i].ID === 'Can participants see ranks:') {
        if (stateData[i].value == 'No') {
          setrank("no");
        }
        else if (stateData[i].value == 'Their own rank') {
          setrank("orank");
        }
        else {
          setrank("lrank");
        }
      }

      if (stateData[i].ID === 'Allow bidding overtime') {
        //alert(this.stateData[i].value)
        if (stateData[i].Value == 'Yes') {
          setovertime(true);
        }
        else {
          setovertime(false);
        }

      }

      if (stateData[i].ID === 'Set a review period after lot closes') {


        if (stateData[i].Value == 'Yes') {

        setreview(true)
        }
        else {
       setreview(false)
        }

      }
      if (stateData[i].ID === 'Specify how lot bidding will begin and end') {

        if (stateData[i].Value == 'Parallel') {
        setbidding("pa")
        }
        else if (stateData[i].Value == 'Staggered') {
         setbidding("st")
        }
        else if (stateData[i].Value == 'Serial') {
         setbidding("se")
        }

      }
  
    }
    stateData?.map((field) => {

      if (field.ID === "Start time") {
        field.Value = new Date(userInput.Start_time);
      }
    });
    var arr_final = [];
    var arr = Object.keys(formTemplate)
    for (let i = 0; i < arr.length; i++) {
      let ele = arr[i];

      for (let j = 0; j < stateData?.length; j++) {
        if (stateData[j].ID == 'Set a review period after lot closes') {
          stateData[j].ID = 'Set a review period after lot or item closes'
        }
        if (ele == stateData[j].ID) {
          arr_final.push(stateData[j])
        }
      }
    }
    setfinal_data(arr_final);
    setdata(arr_final);
    setbackupData(cloneDeep(stateData))
    setmodelRecommendation(stateData)
    setlang("en");
    setlanguageDefaultValue({ label: "EN - English", value: "en" });
  },[]);
  const canExport = () =>
    !errorMessage["Capacity type for the event"] &&
    !errorMessage["Bidding format for the event"] &&
    !errorMessage["Specify how lot bidding will begin and end"] &&
    !errorMessage["Enable preview period before bidding opens"] &&
    !errorMessage[
    "Specify how participants view market information"
    ];

    const validateRequired = (ID, Value) => {
    switch (ID) {
      case "Capacity type for the event":
      case "Bidding format for the event":
      case "Specify how lot bidding will begin and end":
      case "Enable preview period before bidding opens":
        let prevError = { ...errorMessage };
        if (Value === "") {
          prevError[ID] = "Please Provide Value";
          return seterrorMessage(prevError);
        } else {
          prevError[ID] = "";
          return seterrorMessage(prevError);
        }
      default:
        return;
    }
  };

  
const export_api=()=>{

}
  const handleCloseModal = () => {
    setopenModal(false);
  };

  const  handleSelect = (name, indx) => (e) => {
    if (name === 'Can participants see ranks:') {

      if (e.value == 'No') {
        setrank("no");
      }
      else if (e.value == 'Their own rank') {
        setrank("orank")
      }
      else {
        setrank("lrank")
      }
    }
    if (name === 'Allow bidding overtime') {
      if (e.value == 'Yes') {
       setovertime(true)
      }
      else {
        setovertime(false)
      }

    }
    if (name === 'Set a review period after lot or item closes') {
      if (e.value == 'Yes') {
       setreview(true)
      }
      else {
        setreview(false)
      }

    }
    if (name === 'Enable preview period before bidding opens') {
      if (e.value == 'Yes') {
       setperiod(true)
      }
      else {
        setperiod(false)
      }

    }
    if (name === 'Specify how lot bidding will begin and end') {

      if (e.value == 'Parallel') {
        setbidding("pa")
      }
      else if (e.value == 'Staggered') {
        setbidding("st")
      }
      else if (e.value == 'Serial') {
       setbidding("se")
      }

    }


    let updatedData = [...data];

    if (updatedData[indx].ID === name) {
      updatedData[indx].Value = e.value;
      updatedData[indx] = { ...updatedData[indx] };
      validateRequired(name, e.value);
    }

    if (
      updatedData[indx].ID === "Enable preview period before bidding opens" &&
      updatedData[indx].Value.toLowerCase() === "No".toLowerCase()
    ) {
      let biddingStartTime = 0,
        biddingPeriod = 0;
      const newUpdatedData = updatedData.map((e) => {
        if (e.ID === "Bidding period") {
          biddingPeriod = e.Value;
        }
        if (e.ID === "Bidding start time") {
          e.Value = userInput["Start_time"];
          biddingStartTime = userInput["Start_time"];
        }
        // if (e.ID === "Response end time") {
        //   biddingPeriod = parseInt(biddingPeriod.split(" ")[0], 10);
        //   e.Value = addMinutes(new Date(biddingStartTime), biddingPeriod);
        // }
        return e;
      });

      return setdata(newUpdatedData);
    } else if (
      updatedData[indx].ID === "Enable preview period before bidding opens" &&
      updatedData[indx].Value.toLowerCase() === "Yes".toLowerCase()
    ) {
      const newUpdatedData = updatedData.map((e, index) => {
        if (e.ID === "Bidding start time") {
          e.Value = backupData[index].Value;
        }

        if (e.ID === "Response end time") {
          e.Value = backupData[index].Value;
        }
        return e;
      });

      return setdata(newUpdatedData);
    }
    return setdata(updatedData);
  };

  const handleChange = (name, indx) => (e) => {
    let updatedData = [...data];
    if (updatedData[indx].ID === name) {
      updatedData[indx].Value = e.target.value;
      updatedData[indx] = { ...updatedData[indx] };
      validateRequired(name, e.target.value);
    }
     setdata(updatedData);
  };

  const handleDateChange = (name, indx) => (d) => {
    let updatedData = [...data];

    if (updatedData[indx].ID === name) {
      updatedData[indx].Value = d;
      updatedData[indx] = { ...updatedData[indx] };
    }
     setdata(updatedData);
  };

  const handleLanguageChange = (e) => {
    setlang(e.value); setlanguageDefaultValue(e);
  };

  const handleDropdownDefaultValueWithLanguage = (value, lang, defaultValue) => {


    DROPDOWN_LANGUAGE_TRANSLATIONS_OBJECT[lang].map((data) => {
      if (data.value === value) defaultValue.label = data.label;
      defaultValue.value = data.value;
    });
  };

  const renderHeader = (indx) => {


    // const { classes } = this.props;
    return (
      <div className="recommendation_formHeader">
        {RECOMMENDATION_HEADERS[indx]}
        <hr style={{ width: "85%", margin: "4px 0px 4px 0" }}></hr>
      </div>
    );
  };

  const renderStaticInput = (field, indx) => {
    let labelName = lang + "Label";
    let tooltip = lang + "Tooltip";
    let fieldDetails = formTemplate[field.ID];
    let label = fieldDetails[labelName];

    if (field.ID === "Bidding format for the event") {

      switch (lang) {
        case "en":
          field.Value = "English";
          break;
        case "es":
          field.Value = "Inglesa";
          break;
        case "pt":
          field.Value = "Inglesa";
          break;
      }
    }
    if (field.ID === "Bidding format for the event") {
      return (
        <AbevInput
          key={indx}
          required={!fieldDetails.allowNull}
          label={fieldDetails[labelName] ? fieldDetails[labelName] : ""}
          value={field.Value}
          info={fieldDetails[tooltip]}
          handleChange={handleChange(field.ID, indx)}
         helperText={errorMessage[field.ID]}
        />
      );
    }
  };

  const renderForm = (field, indx, arr) => {

    let header;

    if (RECOMMENDATION_HEADERS.hasOwnProperty(indx) && userInput.auctionType==="English")
      header = renderHeader(indx);
    let labelName = lang + "Label";
    let optionsName = lang + "Options";
    let tooltip =lang + "Tooltip";



    let fieldDetails = formTemplate[field.ID];


    if (!fieldDetails) {
      return;
    }
    let label = fieldDetails[labelName];
    if (field.ID == 'Time between lot closing') {
      field.Value = field.Value.toString().split(" ")[0];
    }
    // if (
    //   field.ID === "Running time for the first lot" ||
    //   field.ID === "Bidding period"
    // ) {
    //   // chk if field already has minutes appended
    //   if (field.Value && field.Value.toString().split(" ")[1] !== "Minutes") {
    //     field.Value = field.Value + " Minutes";
    //   }
    // }


    if (field.ID === "Capacity type for the event") {

      switch (lang) {
        case "en":
          field.Value = "Standard";
          break;
        case "es":
          field.Value = "Estándar";
          break;
        case "pt":
          field.Value = "Padrão";
          break;
      }
      return;
    }

    if (
      field.ID === "Bidding format for the event" ||
      field.ID === "Allow participants to select bidding currency"
    ) {
      return;
    }

    if (
      field.ID === "Running time for the first lot" ||
      field.ID === "Bidding period"
    ) {
      if (lang !== "en") label = label + " (minutos)";
      else label = label + " (minutes)";
      if (field.Value && field.Value.toString().split(" ")[1] === "Minutes") {
        field.Value = parseInt(field.Value.toString().split(" ")[0]);
      }
    }

    if (
      field.ID === "Review time period" ||
      field.ID === "Time between lot closing"
    ) {
      if (
        lang !== "en" &&
        field.Value
         &&
        field.Value.toString().split(" ")[1] === "Minutes"
      ) {
        field.Value = field.Value.toString().split(" ")[0] + " Minutos";
      }
      if (
        lang === "en" &&
        field.Value 
        &&
        field.Value.toString().split(" ")[1] === "Minutos"
      ) {
        field.Value = field.Value.toString().split(" ")[0] + " Minutes";
      }
    }

    if (fieldDetails.type === DATA_TYPE.string && !fieldDetails.isDropdown && review) {


      return (
        <>
          {header}
          <AbevInput
            key={indx}
            required={!fieldDetails.allowNull}
            label={fieldDetails[labelName] ? fieldDetails[labelName] : ""}
            value={field.Value}
            info={fieldDetails[tooltip]}
            styles={{ marginLeft: "20%" }}
            handleChange={handleChange(field.ID, indx)}
             helperText={errorMessage[field.ID]}
          />
        </>
      );

    }
    if (fieldDetails.type === DATA_TYPE.string && !fieldDetails.isDropdown && !review) {
      if (field.ID == 'Review time period') {
        return null;
      }
    }


    if (fieldDetails.type === DATA_TYPE.string && !fieldDetails.isDropdown) {
      return (
        <>
          {header}
          <AbevInput
            key={indx}
            required={!fieldDetails.allowNull}
            label={fieldDetails[labelName] ? fieldDetails[labelName] : ""}
            value={field.Value}
            info={fieldDetails[tooltip]}
            styles={{ marginLeft: "20%" }}
            handleChange={handleChange(field.ID, indx)}
            helperText={errorMessage[field.ID]}

          />
        </>
      );
    }
    if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && bidding == 'se') {

      if (["Bidding period"].includes(field.ID)) {
        return null;

      }
      else {
        if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && !overtime) {
          if (["Overtime period (minutes)", "Bid rank that triggers overtime", "Start overtime if bid submitted within (minutes)"].includes(field.ID)) {
            return null;

          }
        }
        if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && overtime) {

          return (
            <>
              {header}
              <AbevInput
                key={indx}
                required={!fieldDetails.allowNull}
                label={label}
                value={field.Value}
                info={fieldDetails[tooltip]}
                styles={{ marginLeft: "20%" }}
                type="number"
                handleChange={handleChange(field.ID, indx)}
                helperText={errorMessage[field.ID]}
              />
            </>
          );


        }
      }

    }
    if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && bidding == 'pa') {

      if (field.ID == 'Running time for the first lot' || field.ID == 'Time between lot closing') {
        return null;

      }
      else {
        if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && !overtime) {
          if (["Overtime period (minutes)", "Bid rank that triggers overtime", "Start overtime if bid submitted within (minutes)"].includes(field.ID)) {
            return null;

          }
        }
        if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && overtime) {

          return (
            <>
              {header}
              <AbevInput
                key={indx}
                required={!fieldDetails.allowNull}
                label={label}
                value={field.Value}
                info={fieldDetails[tooltip]}
                styles={{ marginLeft: "20%" }}
                type="number"
                handleChange={handleChange(field.ID, indx)}
                helperText={errorMessage[field.ID]}
              />
            </>
          );


        }
      }

    }

    if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && !overtime) {
      if (["Overtime period (minutes)", "Bid rank that triggers overtime", "Start overtime if bid submitted within (minutes)"].includes(field.ID)) {
        return null;

      }
    }
    if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && overtime) {

      return (
        <>
          {header}
          <AbevInput
            key={indx}
            required={!fieldDetails.allowNull}
            label={label}
            value={field.Value}
            info={fieldDetails[tooltip]}
            styles={{ marginLeft: "20%" }}
            type="number"
            handleChange={handleChange(field.ID, indx)}
            helperText={errorMessage[field.ID]}
          />
        </>
      );


    }
    if (fieldDetails.type === DATA_TYPE.int && !fieldDetails.isDropdown && field.ID != "Overtime period (minutes)" && field.ID != "Bid rank that triggers overtime" && field.ID != "Start overtime if bid submitted within (minutes)") {
      return (
        <>
          {header}
          <AbevInput
            key={indx}
            required={!fieldDetails.allowNull}
            label={label}
            value={field.Value}
            info={fieldDetails[tooltip]}
            styles={{ marginLeft: "20%" }}
            type="number"
            handleChange={handleChange(field.ID, indx)}
            helperText={errorMessage[field.ID]}
          />
        </>
      );
    }

    if (field.ID == "Can participants place bids during preview period") {
      if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown && period) {
        const defaultValue = {};
        if (lang === "es" || lang === "pt")
          handleDropdownDefaultValueWithLanguage(
            field.Value,
            lang,
            defaultValue
          );
        else {
          defaultValue.label = field.Value;
          defaultValue.value = field.Value;
        }
        return (
          <>
            {header}
            <AbevDropdown
              key={indx}
              required={!fieldDetails.allowNull}
              label={fieldDetails[labelName]}
              defaultValue={defaultValue}
              style={{ marginLeft: "20%" }}
              info={fieldDetails[tooltip]}
              options={fieldDetails[optionsName]}
              handleChange={handleSelect(field.ID, indx)}
            />
          </>
        );
      }
      else if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown && !period) {
        return null;
      }
    }
    // if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown) {
    //   const defaultValue = {};
    //   if (lang === "es" || lang === "pt")
    //     handleDropdownDefaultValueWithLanguage(
    //       field.Value,
    //       lang,
    //       defaultValue
    //     );
    //   else {
    //     defaultValue.label = field.Value;
    //     defaultValue.value = field.Value;
    //   }
    //   return (
    //     <AbevDropdown
    //       key={indx}
    //       required={!fieldDetails.allowNull}
    //       label={fieldDetails[labelName]}
    //       defaultValue={defaultValue}
    //       info={fieldDetails.tooltip}
    //       options={fieldDetails[optionsName]}
    //       handleChange={handleSelect(field.ID, indx)}
    //     />
    //   );
    // }

    if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown && field.ID != 'Show Line Item level rank in Lot') {


      const defaultValue = {};

      if (lang === "es" || lang === "pt")
        handleDropdownDefaultValueWithLanguage(
          field.Value,
          lang,
          defaultValue
        );
      else {
        defaultValue.label = field.Value;
        defaultValue.value = field.Value;
      }
      if (field.ID == 'Can participants submit tie bids') {

        if (field.Value == 'No') {

          if (lang == 'pt') {
            defaultValue.value = "No tie bids";
            defaultValue.label = 'Sem ofertas empatadas'
          }
          else if (lang == 'es') {
            defaultValue.value = "No tie bids";
            defaultValue.label = 'Sin pujas igualadas'
          }
          else {
            defaultValue.value = 'No tie bids';
            defaultValue.label = 'No tie bids'
          }
        }
      }
      return (
        <>
          {header}
          <AbevDropdown
            key={indx}
            required={!fieldDetails.allowNull}
            label={fieldDetails[labelName]}
            defaultValue={defaultValue}
            style={{ marginLeft: "20%" }}
            info={fieldDetails[tooltip]}
            options={fieldDetails[optionsName]}
            handleChange={handleSelect(field.ID, indx)}
          />
        </>
      );
    }

    if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown && ['orank', 'lrank'].includes(rank) && (field.ID == 'Show Line Item level rank in Lot')) {
      const defaultValue = {};
      if (field.Value == 'Yes,to Buyers and Participants') {
        if (lang == 'es') {
          defaultValue.label = "Sí, a compradores y participantes"
        }
        else if (lang == "pt") {
          defaultValue.label = "Sim, para compradores e participantes"
        }
      }
      if (lang === "es" || lang === "pt")
        handleDropdownDefaultValueWithLanguage(
          field.Value,
          lang,
          defaultValue
        );
      else {
        defaultValue.label = field.Value;
        defaultValue.value = field.Value;
      }
      return (
        <>
          {header}
          <AbevDropdown
            key={indx}
            required={fieldDetails.allowNull}
            label={fieldDetails[labelName]}
            defaultValue={defaultValue}
            style={{ marginLeft: "20%" }}
            info={fieldDetails.tooltip}
            options={fieldDetails[optionsName]}
            handleChange={handleSelect(field.ID, indx)}
          />
        </>
      );
    }

    if (fieldDetails.type === DATA_TYPE.string && fieldDetails.isDropdown && rank == 'no' && field.ID == 'Show Line Item level rank in Lot') {
      const defaultValue = {};
      let rankoptionsName = optionsName + "no";

      if (lang === "es" || lang === "pt")
        handleDropdownDefaultValueWithLanguage(
          field.Value,
          lang,
          defaultValue
        );
      else {
        defaultValue.label = field.Value;
        defaultValue.value = field.Value;
      }
      return (
        <>
          {header}
          <AbevDropdown
            key={indx}
            required={fieldDetails.allowNull}
            label={fieldDetails[labelName]}
            defaultValue={defaultValue}
            style={{ marginLeft: "20%" }}
            info={fieldDetails[tooltip]}
            options={fieldDetails[rankoptionsName]}
            handleChange={handleSelect(field.ID, indx)}
          />
        </>
      );
    }

    if (fieldDetails.type === DATA_TYPE.int && fieldDetails.isDropdown) {
      const defaultValue = {};
      defaultValue.label = field.ID;
      defaultValue.value = field.Value;
      return (
        <>
          {header}
          <AbevDropdown
            key={indx}
            required={fieldDetails.allowNull}
            label={fieldDetails[labelName]}
            defaultValue={defaultValue}
            style={{ marginLeft: "20%" }}
            info={fieldDetails[tooltip]}
            options={fieldDetails.options}
            handleChange={handleSelect(field.ID, indx)}
          />
        </>
      );
    }
    if (fieldDetails.type === DATA_TYPE.dateTime) {
      if (field.ID === "Start time" && !field.Value) {
        field.Value = userInput["Start_time"];
      }

      return (
        <></>
      );
    }
  };

    // const { classes } = this.props;
    return (
      <>
        {/* <Navbar auctionType={userInput.auctionType} /> */}
        <div className="recommendation_main">
        
          {viewAccess ?   <div className="recommendation_subMain">
            <div className="recommendation_userInput">
              <div className="recommendation_sectionHeader y_text">Your Input</div>

              {userInput && (
                <UserInputCard userInput={userInput} />
              )}
            </div>
            <div className="recommendation_languageContainer">
              <div className="recommendation_sectionHeader y_text">Language</div>
              <div className="recommendation_preFormContainer">
                <AbevDropdown
                  label="Choose your language"
                  options={LANGUAGE_CONST}
                  defaultValue={languageDefaultValue}
                  handleChange={handleLanguageChange}
                />
                {data.map(renderStaticInput)}
              </div>
            </div>
            <div className="recommendation_container">
              <div className="recommendation_sectionHeader y_text">Recommendation</div>
              {userInput.auctionType === "English"?null
              :<div>
             <BootstrapTable
      bootstrap4
      wrapperClasses="table-responsive mb-2 p-3"
      rowClasses="text-nowrap"
      hover
      keyField={Trigger_Columns_Table[0].dataField}
      data={Item}
      columns={Trigger_Columns_Table}
    /></div>}
              <div className="recommendation_formContainer">
                {/* {console.log(data)} */}
                {data.map(renderForm)}
              </div>
              <AbevButton
                color="primary"
                variant="contained"
                label="Export"
                disable={!canExport()}
                handleClick={downloadtablelink}
              />
               
            </div>
          </div> :   <>
      <Modal
                show={showhideModal}
                dialogClassName="modal-md x-trial"
                centered
                >
                <Modal.Body className="text-center border-bottom-0 text-white">
                  <div className="text-center">
                   
                    <div
                      className="p-2 spectrum-Toast--error text-center"
                      role="alert"
                      >
                      <div className="">
                        <div className="">
                          <span className="spectrum-Heading--subtitle1 text-white avantt-sb h5">
                            Recommendations Restricted
                          </span>
                          <div className="text-white avantt-l pt-3 pb-4 fontSize_12">
                          This auction has been designated for inclusion in the Randomized Control Group. Kindly conduct the auction at your own discretion and ensure to use the below name on SAP Ariba for it to be duly considered for Adoption.<br />
                          <br /> {userInput.uniqueAuctionKey}
                            <AbevCopyToClipboard auctionKey={userInput.uniqueAuctionKey} />

                            <br />
                            
                            <br />
                            You would be redirected to the Home Page
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-marginTop pb-4 abevbtn">
                    <AbevButton
                color="primary"
                variant="contained"
                label="Export"
                disable={!canExport()}
                handleClick={downloadtablelink}
              />
            
                        <a
                        href="/homepage"
                        className="ok_button text-decoration-none text-black"
                        >
                        OK
                      </a>
                     
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
    </>
          }
        </div>
      
      </>
    );

}

export default Recommendation;
