import {
  DATA_TYPE,
  CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW,
  ZONE,
  COMMODITY,
  CURRENCY
} from "./constants";

const formRenderer = {
  "Auction Name": {
    apiKey: "AuctionName",
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: false,
    tooltip: "Auction name",
  },
  Zone: {
    apiKey: "Zone",
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    tooltip: "Zone where auction is conducted",
    options: ZONE,
  },
  "Invited Suppliers": {
    apiKey: "Invited_suppliers",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Total suppliers invited for the Auction",
  },
  "Sellers Without Initial Bid": {
    apiKey: "Sellers_without_initial_bid",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Number of Suppliers which did not provide initial bid",
  },
  "Total Items": {
    apiKey: "Total_items",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Total items being procured",
  },
  Commodity:{
    apiKey: "Commodity",
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    tooltip: "Commodity for Auction",
    options: COMMODITY,
  },
  Currency:{
    apiKey: "Currency",
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    tooltip: "Currency for Auction",
    options: CURRENCY,
  },
  "Best First Quote": {
    apiKey: "Bfq",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Best First Quote for all Items Combined(Extended Price)",
    text:"In case of missing Best First Quote, please leave the field blank",
    text1:"NOTE: Auction Edge provides better recommendations with Best First Quote"
  },
 
  "Second Best First Quote": {
    apiKey: "Second_Bfq",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Best Second Quote for all Items Combined(Extended Price)",
    text:"In case of missing Second-Best First Quote, please leave the field blank",
    text1: "NOTE: Auction Edge provides better recommendations with Second-Best First Quote"
  },
  "Baseline Spend": {
    apiKey: "Baseline_spend",
    type: DATA_TYPE.int,
    allowNull: false,
    isDropdown: false,
    tooltip: "Baseline spend for all items combined",
  },
  // "Start time": {
  //   apiKey: "Start_time",
  //   type: DATA_TYPE.dateTime,
  //   allowNull: false,
  //   isDropdown: false,
  //   tooltip: "Start time is when the event is available for preview",
  // }
};

export default formRenderer;
