import React from "react";
import "./Login.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import logo from "../../images/procurement.png";
function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {});
}
const Login = () => {
  const { instance } = useMsal();

  return (
    <div className="login-page">
      <div className="login-content">
        <div className="login-text">
          <h1 className="text-center text-white login-text-header heading avantt-b">
          </h1>
          <img src={logo} width="auto" height="95" className="px-4 pt-4 pb-0"></img>
          
          <p className="pb-2 my-5 pt-5 text-center login-text-body text-white avantt-l">
            Please use your AB Inbev ID to login
          </p>
          <button
            onClick={() => handleLogin(instance)}
            className="mb-5 avantt-sb"
            data-testid="loginBtn"
          >
            Login
          </button>
          <p className="blank-line"></p>
        </div>
      </div>
    </div>
  );
};

export default Login;

