import axios from "axios";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 toast.configure();

export const HTTP_SERVICE_CALL = async (url, type, body, params) => {
  return new Promise(async function (resolve, reject) {
    return await axios({
      method: type,
      url: url,
      crossDomain: true,
      headers: { "Content-Type": "application/json" },
      data: body,
      params: params,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return resolve(response);
        }
      })
      .catch((err) => {
        if ((err?.response?.data?.[1] === 400 && err?.response?.data?.[0]?.Error === "Token or User invalid") || (err?.response?.data?.[1] === 401)) {
          let error_json = {
            message: err?.response?.data?.[0]?.Error,
            status: err?.response?.data?.[1],
          };
          return reject(error_json);
        }else {
          return reject(err.toJSON());
        }
      });
  });
};
export const gloabaltoastmessage = (message,duration,type) => {
		          if(type=='success'){
          toast.success(message, {
          position: toast.POSITION.TOP_RIGHT, autoClose:duration
          })
        }else if(type=='error'){
          toast.error(message, {
          position: toast.POSITION.TOP_RIGHT, autoClose:duration
          })
        }else if(type=='warning'){
          toast.warning(message, {
          position: toast.POSITION.TOP_RIGHT, autoClose:duration
          })
        }else{
          toast.info(message, {
          position: toast.POSITION.TOP_RIGHT, autoClose:false
          })  
        }    
}
