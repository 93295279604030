import React from "react";
import Title from "./Title/Title";



export default function AbevInput({
  label,
  variant,
  helperText,
  error,
  handleChange,
  info,
  required,
  type,
  value,
  validationMessage,
  styles,
  text,
  text1,
  ...props
}) {
  return (
    <div className="dropdown_container">
      <div className="labelContainer" >
      <label >{label}</label>
        <span className="asterix" >{required ? "*" : ""}</span>
        {info && (
          <div className="info">
            <Title text={info} />
          </div>
        )}
      </div>

      <div >
        {label=="Best First Quote" ||  label=="Second Best First Quote" ||  label=="Baseline Spend" ||  label=="BFQ Spend"||  label=="Historic Spend" ? (   <input
          variant={variant || "outlined"}
          helperText={helperText}
          error={error}
          value={value}
          step="0.1"
          onChange={handleChange}
          type={type}
          className="input_css"
        />) :(   <input
          variant={variant || "outlined"}
          helperText={helperText}
          error={error}
          value={value}
          step="1"
          onKeyPress={(event) => {
            if (event.key === '.' || event.key === ',' || event.key === '-' || event.key === '+') {
              event.preventDefault();
            }}}
          onChange={handleChange}
          type={type}
          className="input_css"
        />)}
     
     <span className="helper">{helperText}</span>
        {/* {validationMessage && (
          <span className={classes.validateMsg}>{validationMessage}</span>
        )} */}
      </div>
      <div>
      {text && (
          <div className="validateMsg">
            {text}
            <br/>
            {text1}
          </div>
        )}
        
        </div>
    </div>
    
  );
}
