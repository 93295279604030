import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./UserAccessModal.css";
const UserAccessModal = (props) => {
  const [showhideModal, setShowHideModal] = useState(true);
  return (
    <>
      <Modal
                show={showhideModal}
                dialogClassName="modal-sm x-trial"
                centered
                >
                <Modal.Body className="text-center border-bottom-0 text-white">
                  <div className="text-center">
                   
                    <div
                      className="p-2 spectrum-Toast--error text-center"
                      role="alert"
                      >
                      <div className="">
                        <div className="">
                          <span className="spectrum-Heading--subtitle1 text-white avantt-sb h5">
                            Recommendations Denied
                          </span>
                          <div className="text-white avantt-l pt-3 pb-4 fontSize_12">
                            You have generated the unique auction key.
                            <br />
                            Kindly reach out to product team using the “Talk to us”
                            Section on Home Page.
                            <br />
                            You would be redirected to the Home Page
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="u-marginTop pb-4">
                        <a
                        href="/homepage"
                        className="ok_button text-decoration-none text-black"
                        >
                        OK
                      </a>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
    </>
  );
};
export default UserAccessModal;
