import React from "react";
import AbevCopyToClipboard from "./AbevCopyToClipboard";
export default function UserInputCard(props) {
  let KEYS_TO_DISPLAY;let keyToNameMap;
  if(props.userInput.auctionType=="English"){
    KEYS_TO_DISPLAY = [
      "AuctionName",
      "Zone",
      "Invited Suppliers",
      "Sellers Without Initial Bid",
      "Total Items",
      "Commodity",
      "Currency",
      "Best First Quote",
      "Second Best First Quote",
      "Baseline Spend",    
      "uniqueAuctionKey",   
      "Start time",
      "auctionType",
 ];
    keyToNameMap = {
      Zone: "Zone",
      AuctionName: "Auction Name",
      ["Invited Suppliers"]: "Invited suppliers",
      ["Sellers Without Initial Bid"]: "Sellers without initial bid",
      ["Total Items"]: "Total items",
      ["Best First Quote"]: "Best First Quote",
      ["Second Best First Quote"]:"Second Best First Quote",
      ["Commodity"]:"Commodity",
      "Currency":"Currency",
      ["Baseline Spend"]: "Baseline Spend",
      ["Start time"]:"Start time",
      uniqueAuctionKey: "Auction Name With Unique Key",
      auctionType:"Auction Type"
      
    };
  }
else{
  KEYS_TO_DISPLAY =[
    "auction_name",
    "zone",
    "commodity",
    "output_currency",
    "invited_suppliers",
    "baseline_spend",
    "baseline_currency",
    "sellers_nobid",
    "items",
    "auctionType",
    "hashValue",
    "uniqueAuctionKey"
  ];
  keyToNameMap = {
    auctionType:"Auction Type",
    zone: "Zone",
    auction_name: "Auction Name",
    invited_suppliers: "Invited suppliers",
    sellers_nobid: "Sellers without initial bid",
    commodity:"Commodity",
    output_currency:"Output Currency",
    baseline_spend: "Baseline Spend",
    baseline_currency: "Baseline Currency",
    uniqueAuctionKey: "Auction Name With Unique Key",
    
  };
}
  return (

    <div className="user_container">
     
      {Object.keys(props.userInput)
        .filter((e) => KEYS_TO_DISPLAY.includes(e))
        .map((key, indx) => {
          return (
            <div className="user_keycontainer" key={indx}>
              <div className="user_label">{keyToNameMap[key]}:</div>
              <div className="user_value">

                {key!=="items"? props.userInput[key]:null}
                {key === "uniqueAuctionKey" ? (
                  <AbevCopyToClipboard auctionKey={props.userInput[key]} />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        <div className="user_label_text">Enter bidding time on Ariba</div>
    </div>
  );
}
