import {
  DATA_TYPE,
  CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_EN,
  HOW_LOT_BIDDING_BEGIN_AND_END_EN,
  SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_EN,
  ALLOW_BIDDING_OVERTIME_EN,
  CREATE_BUFFER_TO_PROTECT_LEAD_BID_EN,
  PARTICIPANTS_SUBMIT_TIE_BIDS_EN,
  ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_EN,
  MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_EN,
  REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_EN,
  HOW_PARTICIPANT_VIEW_MARKET_INFO_EN,
  SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_EN,
  PARTICIPANTS_CAN_SEE_RANKS_EN,
  LINE_ITEM_LEVEL_RANK_IN_LOT_EN,
  DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_EN,
  SHOW_FORMULAS_TO_ALL_PARTICIPANTS_EN,
  SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_EN,
  CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_EN,
  ENABLE_TRAFFIC_LIGHT_BIDDING_EN,
  IMPROVE_BID_AMOUNT_BY_EN,
  SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_EN,
  ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_EN,
  CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_ES,
  HOW_LOT_BIDDING_BEGIN_AND_END_ES,
  SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_ES,
  ALLOW_BIDDING_OVERTIME_ES,
  CREATE_BUFFER_TO_PROTECT_LEAD_BID_ES,
  PARTICIPANTS_SUBMIT_TIE_BIDS_ES,
  ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_ES,
  MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_ES,
  REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_ES,
  HOW_PARTICIPANT_VIEW_MARKET_INFO_ES,
  SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_ES,
  PARTICIPANTS_CAN_SEE_RANKS_ES,
  LINE_ITEM_LEVEL_RANK_IN_LOT_ES,
  DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_ES,
  SHOW_FORMULAS_TO_ALL_PARTICIPANTS_ES,
  SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_ES,
  CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_ES,
  ENABLE_TRAFFIC_LIGHT_BIDDING_ES,
  IMPROVE_BID_AMOUNT_BY_ES,
  SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_ES,
  ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_ES,
  CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_PT,
  HOW_LOT_BIDDING_BEGIN_AND_END_PT,
  SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_PT,
  ALLOW_BIDDING_OVERTIME_PT,
  CREATE_BUFFER_TO_PROTECT_LEAD_BID_PT,
  PARTICIPANTS_SUBMIT_TIE_BIDS_PT,
  ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_PT,
  MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_PT,
  REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_PT,
  HOW_PARTICIPANT_VIEW_MARKET_INFO_PT,
  SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_PT,
  PARTICIPANTS_CAN_SEE_RANKS_PT,
  LINE_ITEM_LEVEL_RANK_IN_LOT_PT,
  DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_PT,
  SHOW_FORMULAS_TO_ALL_PARTICIPANTS_PT,
  SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_PT,
  CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_PT,
  ENABLE_TRAFFIC_LIGHT_BIDDING_PT,
  IMPROVE_BID_AMOUNT_BY_PT,
  SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_PT,
  ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_PT,
  LINE_ITEM_LEVEL_RANK_IN_LOT_EN_NO ,
  LINE_ITEM_LEVEL_RANK_IN_LOT_PT_NO,
  LINE_ITEM_LEVEL_RANK_IN_LOT_ES_NO,
  // DUTCH
  ADJUST_BID_AMOUNT_BY_EN,
  ADJUST_BID_AMOUNT_BY_ES,
  ADJUST_BID_AMOUNT_BY_PT,
  HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_EN,
  HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_ES,
  HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_PT,
  REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_EN,
  REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_ES,
  REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_PT,
  SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_EN,
  SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_ES,
  SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_PT,
  //JAPANESE
  ALLOW_COST_GROUPS_TO_CAPTURE_DETAILED_COST_BREAKDOWNS_EN,
  ENABLE_APPROVAL_FOR_TEAM_GRADING_EN,
  Hide_winning_message_from_participants_EN,
  Hide_winning_message_from_participants_ES,
  Hide_winning_message_from_participants_PT,


} from "../Constants";

const formRenderer = {
  "Bidding format for the event": {
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: false,
    enTooltip: "",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Bidding format for the event",
    esLabel: "Formato de puja para el evento",
    ptLabel: "Formato da cotação do evento",
  },
  "Capacity type for the event": {
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: false,
    enTooltip: "",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Capacity type for the event",
    esLabel: "Tipo de capacidad para el evento",
    ptLabel: "Tipo de capacidade do evento",
  },
  "Enable preview period before bidding opens": {
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip:
      "During the preview period, participants can ask questions about confusing points or submit prebids. It is advised to enable preview period",
    esTooltip:
      "Durante el periodo de vista preliminar los participantes pueden revisar el evento y determinar cuánto van a pujar una vez que se abra el proceso de puja.",
    ptTooltip:
      "Durante a visualização, os participantes revisam o evento e determinam o valor da oferta quando a cotação é aberta.",
    enLabel: "Enable preview period before bidding opens",
    esLabel:
      "Permitir periodo de vista preliminar antes de la apertura del proceso de puja",
    ptLabel: "Ativar período de visualização antes da abertura da cotação",
    enOptions: ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_EN,
    esOptions: ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_ES,
    ptOptions: ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_PT,
  },
  "Can participants place bids during preview period": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If a supplier submits a prebid, it becomes their initial bid when bidding opens",
    esTooltip:
      "Especifique si los participantes pueden enviar pujas previas o si solamente pueden revisar información durante el periodo de vista preliminar.",
    ptTooltip:
      "Especifique se os participantes podem fazer pré-ofertas ou apenas revisar informações durante o período de visualização.",
    enLabel: "Can participants place bids during preview period",
    esLabel:
      "¿Pueden los participantes presentar pujas durante el periodo de vista preliminar?",
    ptLabel:
      "Os participantes podem fazer ofertas durante o período de visualização",
    enOptions: CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_EN,
    esOptions: CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_ES,
    ptOptions: CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_PT,
  },
  // "Start time": {
  //   type: DATA_TYPE.dateTime,
  //   allowNull: true,
  //   isDropdown: false,
  //   enTooltip: "Start time is when the event is available for preview",
  // },

  "Specify how lot bidding will begin and end": {
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip:
      "Parallel: All lots begin and close at same time Staggered: All lots bidding start at the same time but close sequentially; Serial: Bidding for one lot ends before bidding for the next one begins ( Serial / Staggered preferred with multiple items)",
    esTooltip:
      "Seleccione la secuencia relativa de horarios de apertura y cierre de los diferentes lotes.",
    ptTooltip:
      "Selecione a sequência relativa do cronograma de abertura e fechamento para lotes diferentes.",
    enLabel: "Specify how lot bidding will begin and end",
    esLabel: "Especificar cómo empezará y finalizará la puja por lotes",
    ptLabel: "Especificar como a cotação do lote será iniciada e finalizada",
    enOptions: HOW_LOT_BIDDING_BEGIN_AND_END_EN,
    esOptions: HOW_LOT_BIDDING_BEGIN_AND_END_ES,
    ptOptions: HOW_LOT_BIDDING_BEGIN_AND_END_PT,
  },

  // "Bidding start time": {
  //   type: DATA_TYPE.dateTime,
  //   allowNull: true,
  //   isDropdown: false,
  //   enTooltip: "Bidding start time is when participants are first able to submit responses",
  // },

  "Bidding period": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enLabel: "Bidding period",
    esLabel: "Periodo de puja",
    ptLabel: "Período de cotação",
    enTooltip: "Length of Bidding Period for the event",
    esTooltip: "Duración del período de licitación para el evento",
    ptTooltip: "Duração do período de licitação para o evento",
  },

  "Running time for the first lot": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enLabel: "Running time for the first lot",
    esLabel: "Tiempo de ejecución para el primer lote",
    ptLabel: "Tempo de execução do primeiro lote",
    enTooltip:
      "Time during which the first lot is open for bidding [Staggered/ Serial]",
    esTooltip: "Este es el tiempo de ejecución para el primer lote.",
    ptTooltip:
      "Trata-se do tempo de execução referente ao primeiro lote. Se a prorrogação for acionada, o tempo de execução será prorrogado.",
  },

  "Time between lot closing": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enLabel: "Time between lot closing",
    esLabel: "Tiempo entre cierres de lotes",
    ptLabel: "Tempo entre o fechamento do lote",
    enTooltip:
      "Interval between the closing of each lot's bidding period [Staggered/ Serial]",
    esTooltip:
      "Esta es la cantidad de tiempo que ha de pasar hasta que se cierre el siguiente lote, para asegurar que los participantes tienen tiempo suficiente para pujar por cada lote.",
    ptTooltip:
      "Esse tempo deve passar antes do fechamento do próximo lote para garantir que os participantes tenham tempo suficiente para fazer a oferta em cada lote.",
  },

  "Set a review period after lot or item closes": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If Yes, during this time owner reviews the bidding results, and can reopen bidding if necessary",
    esTooltip:
      "If Yes, during this time owner reviews the bidding results, and can reopen bidding if necessary",
    ptTooltip:
      "If Yes, during this time owner reviews the bidding results, and can reopen bidding if necessary",
    enLabel: "Set a review period after lot or item closes",
    esLabel:
      "Establecer un periodo de revisión una vez que se haya cerrado el lote",
    ptLabel: "Definir um período de revisão após o fechamento do lote",
    enOptions: SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_EN,
    esOptions: SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_ES,
    ptOptions: SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_PT,
  },

  "Review time period": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: false,
    enTooltip: "The time allotted for the owner to review bid results",
    esTooltip:
      "El periodo designado por el propietario para revisar los resultados de puja, y para establecer comunicación entre propietario y participantes.",
    ptTooltip:
      "O período designado pelo proprietário para revisar os resultados da licitação e para estabelecer a comunicação entre o proprietário e os participantes.",
    enLabel: "Review time period",
    esLabel: "Periodo de revisión",
    ptLabel: "Período para revisão",
  },

  "Allow bidding overtime": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "Overtime gives participants additional time to respond to late bids of other participants. Generally set to Yes",
    esTooltip:
      "La prórroga en una subasta permite al propietario ampliar el periodo de puja cuando una puja se ha recibido demasiado cerca de la hora de finalización. ",
    ptTooltip:
      "A prorrogação da cotação permite que o proprietário estenda o período de cotação sempre que uma oferta for recebida em um momento muito próximo à hora de término.",
    enLabel: "Allow bidding overtime",
    esLabel: "Permitir pujas en la prórroga",
    ptLabel: "Permitir prorrogação da cotação",
    enOptions: ALLOW_BIDDING_OVERTIME_EN,
    esOptions: ALLOW_BIDDING_OVERTIME_ES,
    ptOptions: ALLOW_BIDDING_OVERTIME_PT,
  },

  "Bid rank that triggers overtime": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "Example: If it is set to 2, then a new bid ranked first or second in last minutes can trigger overtime",
    esTooltip:
      "Por ejemplo, si especifica 3, una puja en las posiciones 1ª, 2ª o 3ª, disparará la prórroga.",
    ptTooltip:
      "Por exemplo, se você especificar 3, a oferta em primeiro, segundo ou terceiro lugar acionará a prorrogação.",
    enLabel: "Bid rank that triggers overtime",
    esLabel: "Posición de puja que dispara prórroga",
    ptLabel: "Posição da oferta que aciona a prorrogação",
  },

  "Start overtime if bid submitted within (minutes)": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "If a bid of the specified rank or better arrives within this number of minutes of the end of the bidding period, it triggers overtime",
    esTooltip:
      "Si se envía una puja y el tiempo que queda del periodo abierto para puja se encuentra dentro de este periodo, el proceso de puja se amplía a la prórroga",
    ptTooltip:
      "Se uma oferta for enviada e ainda houver tempo para cotação dentro deste período, haverá prorrogação da cotação.",
    enLabel: "Start overtime if bid submitted within (minutes)",
    esLabel: "Iniciar la prórroga si se envía una puja en (minutos)",
    ptLabel: "Iniciar prorrogação se a oferta for enviada dentro de (minutos)",
  },

  "Overtime period (minutes)": {
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "If overtime is triggered, this rule specifies how long it lasts",
    esTooltip:
      "Si se dispara una prórroga, esta regla especifica cuánto dura el periodo de prórroga.",
    ptTooltip:
      "Se a prorrogação é acionada, esta regra especifica a duração do período de prorrogação.",
    enLabel: "Overtime period (minutes)",
    esLabel: "Periodo de prórroga (minutos)",
    ptLabel: "Período de prorrogação (minutos)",
  },
  "Enable traffic light bidding": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "Suppliers receive feedback about whether their bid will be considered in the form of green, yellow, and red traffic light icons Range set while entering item details",
    esTooltip:
      "Los proveedores reciben respuestas sobre si sus pujas se van a considerar a través de iconos de semáforo en verde, amarillo y rojo.",
    ptTooltip:
      "Cotação de semáforo é um tipo de concorrência reversa inglesa em que os fornecedores enviam ofertas decrescentes. Eles recebem feedback informando se a oferta será considerada em formato de ícones de semáforo verdes, amarelos e vermelhos. Normalmente, ela é usada para filtrar fornecedores em um evento de sourcing de várias fases",
    enLabel: "Enable traffic light bidding",
    esLabel: "Activar puja de semáforo",
    ptLabel: "Ativar cotação de semáforo",
    enOptions: ENABLE_TRAFFIC_LIGHT_BIDDING_EN,
    esOptions: ENABLE_TRAFFIC_LIGHT_BIDDING_ES,
    ptOptions: ENABLE_TRAFFIC_LIGHT_BIDDING_PT,
  },

  "Create a buffer to protect lead bid": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "A buffer ensures that no participant can take the lead by bidding just a penny better than the lead bid",
    esTooltip:
      "Crear un margen de protección alrededor de la puja líder dentro del cual el sistema no permite que los participantes competidores pujen.",
    ptTooltip:
      "Crie uma zona de proteção para a oferta líder onde o sistema não permita que os participantes concorrentes façam a oferta. O limite da zona inicial de proteção determina a extensão da zona na frente da oferta líder. O limite da zona final determina a extensão da zona atrás da oferta líder.",
    enLabel: "Create a buffer to protect lead bid",
    esLabel:
      "Crear un margen de protección de puja para proteger la puja líder",
    ptLabel: "Criar uma zona para proteger a oferta líder",
    enOptions: CREATE_BUFFER_TO_PROTECT_LEAD_BID_EN,
    esOptions: CREATE_BUFFER_TO_PROTECT_LEAD_BID_ES,
    ptOptions: CREATE_BUFFER_TO_PROTECT_LEAD_BID_PT,
  },

  "Improve bid amount by": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "Specify whether the participants must improve their bids by a nominal amount or a percentage",
    esTooltip:
      "Especificar si los participantes han de mejorar sus pujas por un importe nominal o por un porcentaje. ",
    ptTooltip:
      "Especifique se os participantes devem melhorar suas ofertas em valor nominal ou porcentagem.",
    enLabel: "Improve bid amount by",
    esLabel: "Mejorar el importe de la puja en",
    ptLabel: "Melhorar o valor da oferta em",
    enOptions: IMPROVE_BID_AMOUNT_BY_EN,
    esOptions: IMPROVE_BID_AMOUNT_BY_ES,
    ptOptions: IMPROVE_BID_AMOUNT_BY_PT,
  },

  "Can participants submit tie bids": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "This rule prevents participants from placing same amount bids. Generally set to No",
    esTooltip: "Os lances correspondentes são aqueles que têm a mesma posição.",
    ptTooltip: "Ofertas empatadas são ofertas que têm a mesma classificação.",
    enLabel: "Can participants submit tie bids",
    esLabel: "¿Pueden los participantes enviar pujas igualadas?",
    ptLabel: "Os participantes podem enviar ofertas empatadas",
    enOptions: PARTICIPANTS_SUBMIT_TIE_BIDS_EN,
    esOptions: PARTICIPANTS_SUBMIT_TIE_BIDS_ES,
    ptOptions: PARTICIPANTS_SUBMIT_TIE_BIDS_PT,
  },

  "Require participant to give a reason for declining to bid": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If reason for declining to bid on an item is required. Generally set to No",
    esTooltip:
      "Requerir al participante que dé una razón por la que declina pujar o responder a un lote o un artículo en línea",
    ptTooltip:
      "Exigir que o participante informe um motivo para recusar a realização de oferta ou a resposta para um lote ou item de linha.",
    enLabel: "Require participant to give a reason for declining to bid",
    esLabel:
      "Requerir al participante que dé una razón por la que declina pujar",
    ptLabel:
      "Exigir que o participante informe um motivo para recusar a realização de oferta",
    enOptions: REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_EN,
    esOptions: REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_ES,
    ptOptions: REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_PT,
  },

  "Must participants bid on all items": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip: "Allow participants not bid on all items. Generally set to No",
    esTooltip: "Allow participants not bid on all items. Generally set to No",
    ptTooltip: "Allow participants not bid on all items. Generally set to No",
    enLabel: "Must participants bid on all items",
    esLabel: "Must participants bid on all items",
    ptLabel: "Must participants bid on all items",
    enOptions: MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_EN,
    esOptions: MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_ES,
    ptOptions: MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_PT,
  },

  "Show formulas to all participants": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "The expressions, such as (Price * Quantity) used to calculate formulas (such as Extended Price)",
    esTooltip:
      "Las expresiones, tales como (Precio * Cantidad) utilizadas para calcular fórmulas (tal como Subtotal).",
    ptTooltip:
      "Expressões, como (Preço * Quantidade), usadas para calcular fórmulas (como Preço estendido).",
    enLabel: "Show formulas to all participants",
    esLabel: "Mostrar fórmulas a todos los participantes",
    ptLabel: "Mostrar fórmulas a todos os participantes",
    enOptions: SHOW_FORMULAS_TO_ALL_PARTICIPANTS_EN,
    esOptions: SHOW_FORMULAS_TO_ALL_PARTICIPANTS_ES,
    ptOptions: SHOW_FORMULAS_TO_ALL_PARTICIPANTS_PT,
  },

  "Specify how participants view market information": {
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "How and when to show market information to suppliers",
    esTooltip: "Cómo y cuándo mostrar información de mercado a los proveedores",
    ptTooltip: "Como e quando mostrar informações de mercado aos fornecedores",
    enLabel: "Specify how participants view market information",
    esLabel: "Especificar cómo ven los participantes la información de mercado",
    ptLabel:
      "Especificar como os participantes exibem as informações do mercado",
    enOptions: HOW_PARTICIPANT_VIEW_MARKET_INFO_EN,
    esOptions: HOW_PARTICIPANT_VIEW_MARKET_INFO_ES,
    ptOptions: HOW_PARTICIPANT_VIEW_MARKET_INFO_PT,
  },

  "Show participant responses to other participants": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "Controls when participants can see price, quantity, participant aliases, responses, item terms, bid score, total cost and rank details from other participant response",
    esTooltip:
      "Esta regla se aplica solo a lotes con puja de artículo en línea. Si selecciona No, Ariba Sourcing no muestra la posición de las pujas a nivel de artículo en línea ni a usted ni a los participantes.",
    ptTooltip:
      "Esta regra se aplica apenas a lotes com lances de itens online. Se você selecionar Não, o Ariba Sourcing não exibirá a posição dos lances em nível de item online para você ou participantes.",
    enLabel: "Show participant responses to other participants",
    esLabel: "Mostrar la posición de nivel de artículo en línea en el lote",
    ptLabel: "Mostrar respostas do participante para outros participantes ",
    enOptions: SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_EN,
    esOptions: SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_ES,
    ptOptions: SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_PT,
  },

  "Show lead bid to all participants": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If participants cannot see lead bid information, they see only their own responses",
    esTooltip:
      "Si los participantes no pueden ver la información sobre la puja líder, verán solamente sus propias respuestas. ",
    ptTooltip:
      "Se os participantes não puderem ver as informações da oferta líder, eles verão apenas suas próprias respostas.",
    enLabel: "Show lead bid to all participants",
    esLabel: "Mostrar puja líder a todos los participantes",
    ptLabel: "Mostrar a oferta líder a todos os participantes",
    enOptions: SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_EN,
    esOptions: SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_ES,
    ptOptions: SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_PT,
  },

  "Can participants see ranks:": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If participant can see other participants rank; optimal value varies with the number of sellers invited",
    esTooltip:
      "Su propia posición cuando están en cabeza permite a los participantes ver si sus pujas se encuentran a la cabeza del mercado o no.",
    ptTooltip:
      "Apenas quando estiver vencendo permite que os participantes vejam se a oferta deles está como líder de mercado ou não.",
    enLabel: "Can participants see ranks:",
    esLabel: "Pueden los participantes ver las posiciones:",
    ptLabel: "Os participantes podem ver as classificações:",
    enOptions: PARTICIPANTS_CAN_SEE_RANKS_EN,
    esOptions: PARTICIPANTS_CAN_SEE_RANKS_ES,
    ptOptions: PARTICIPANTS_CAN_SEE_RANKS_PT,
  },

  "Show Line Item level rank in Lot": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "This rule applies only to Lots with Line Item bidding and enables or disables the visibility of ranks",
    esTooltip:
      "Esta regla se aplica solo a lotes con puja de artículo en línea. Si selecciona No, Ariba Sourcing no muestra la posición de las pujas a nivel de artículo en línea ni a usted ni a los participantes",
    ptTooltip:
      "Esta regra se aplica apenas a Lotes com cotação de item de linha. Se for selecionado Não, o Ariba Sourcing não exibirá a posição das ofertas do nível do item de linha a você e aos participantes. ",
    enLabel: "Show Line Item level rank in Lot",
    esLabel: "Mostrar la posición de nivel de artículo en línea en el lote",
    ptLabel: "Mostrar posição do nível do item de linha no lote",
    enOptions: LINE_ITEM_LEVEL_RANK_IN_LOT_EN,
    esOptions: LINE_ITEM_LEVEL_RANK_IN_LOT_ES,
    ptOptions: LINE_ITEM_LEVEL_RANK_IN_LOT_PT,
    enOptionsno: LINE_ITEM_LEVEL_RANK_IN_LOT_EN_NO,
    esOptionsno: LINE_ITEM_LEVEL_RANK_IN_LOT_ES_NO,
    ptOptionsno: LINE_ITEM_LEVEL_RANK_IN_LOT_ES_NO,
  },

  "Show calculated value of competitive term before participant submits bid": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "No prevents participants from determining the formula for a competitive value until they submit a bid",
    esTooltip:
      "No impide a los participantes determinar la fórmula para un valor competitivo hasta que hayan enviado una puja.",
    ptTooltip:
      "A opção Não impede que os participantes determinem a fórmula de um valor competitivo até que eles enviem uma oferta.",
    enLabel:
      "Show calculated value of competitive term before participant submits bid",
    esLabel:
      "Mostrar valor calculado de condición competitiva antes de que el participante envíe la puja",
    ptLabel:
      "Mostrar o valor calculado da condição competitiva antes que o participante envie a oferta",
    enOptions: DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_EN,
    esOptions: DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_ES,
    ptOptions: DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_PT,
  },

  "Indicate to participants that participant-specific initial values have been specified":
    {
      type: DATA_TYPE.string,
      allowNull: true,
      isDropdown: true,
      enTooltip:
        "When enabled, participants are informed through the lot rules that different participants have been assigned different initial values",
      esTooltip:
        "Cuando esta opción está activada, se informa a los participantes a través de las reglas de lote de que se han asignado diferentes valores iniciales para cada participante.",
      ptTooltip:
        "Quando ativado, os participantes são informados pelas regras do lote de que diferentes participantes receberam valores iniciais diversos",
      enLabel:
        "Indicate to participants that participant-specific initial values have been specified",
      esLabel: "Mostrar el gráfico de pujas a todos los participantes",
      ptLabel:
        "Indicar aos participantes que foram determinados valores iniciais específicos do participante",
      enOptions: SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_EN,
      esOptions: SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_ES,
      ptOptions: SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_PT,
    },

  "Can owner see responses before event closes": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "If no, then you cannot view participant responses until responses are no longer being accepted",
    esTooltip:
      "Si la respuesta es no, no podrá ver las respuestas de los participantes hasta que ya no se acepten.",
    ptTooltip:
      "Se não, você não pode ver as respostas dos participantes até que elas não sejam mais aceitas",
    enLabel: "Can owner see responses before event closes",
    esLabel:
      "Indicar a los participantes que se han especificado valores iniciales específicos de participante",
    ptLabel:
      "O proprietário pode ver as respostas antes do fechamento do evento",
    enOptions: CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_EN,
    esOptions: CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_ES,
    ptOptions: CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_PT,
  },

  // "Response end time": {
  //   type: DATA_TYPE.dateTime,
  //   allowNull: true,
  //   isDropdown: false,
  //   enTooltip: "Time when bidding stops",
  // },

  "Allow participants to select bidding currency": {
    type: DATA_TYPE.string,
    allowNull: true,
    isDropdown: true,
    enTooltip:
      "Allow participants to choose which currency to place their bids in. Generally set to No",
    esTooltip: "Allow participants to choose which currency to place their bids in. Generally set to No",
    ptTooltip: "Allow participants to choose which currency to place their bids in. Generally set to No",
    enLabel: "Allow participants to select bidding currency",
    esLabel: "Permitir a los participantes seleccionar la divisa de puja",
    ptLabel: "Permitir que os participantes selecionem a moeda da cotação",
    enOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_EN,
    esOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_ES,
    ptOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_PT,
  },

  "Adjust bid amount by":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Adjust bid amount by",
    esLabel: "Ajustar el importe de la puja en",
    ptLabel:
      "Ajustar o valor da oferta em",
    enOptions: ADJUST_BID_AMOUNT_BY_EN,
    esOptions: ADJUST_BID_AMOUNT_BY_ES,
    ptOptions: ADJUST_BID_AMOUNT_BY_PT,
  },
  "Allow participants to select bidding currency":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Allow participants to select bidding currency",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Allow participants to select bidding currency",
    esLabel: "Permitir a los participantes seleccionar la divisa de puja",
    ptLabel:
      "Permitir que os participantes selecionem a moeda da cotação",
    enOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_EN,
    esOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_ES,
    ptOptions: ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_PT,
  },
  "Bid adjustment interval":{
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "This rule specifies the interval at which the bids change by the amount specified in the Improve bid amount by bidding rule",
    esTooltip:
    "This rule specifies the interval at which the bids change by the amount specified in the Improve bid amount by bidding rule",
    ptTooltip:
    "This rule specifies the interval at which the bids change by the amount specified in the Improve bid amount by bidding rule",
    enLabel: "Bid adjustment interval (minutes)",
    esLabel: "Intervalo de ajuste de puja",
    ptLabel: "Intervalo de ajuste da oferta",
  },
  "Hide countdown clock from participants":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Hide countdown clock from participants",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Hide countdown clock from participants",
    esLabel: "Ocultar reloj de cuenta atrás a los participantes",
    ptLabel:
      "Ocultar relógio de contagem regressiva dos participantes",
    enOptions: HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_EN,
    esOptions: HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_ES,
    ptOptions: HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_PT,
  },
  "Require participant to give a reason for declining to bid":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Require participant to give a reason for declining to bid",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Require participant to give a reason for declining to bid",
    esLabel: "Requerir al participante que dé una razón por la que declina pujar",
    ptLabel:
      "Permitir que os participantes selecionem a moeda da cotação",
    enOptions: REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_EN,
    esOptions: REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_ES,
    ptOptions: REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_PT,
  },
  "Running time for the first lot":{
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the time during which the first lot is open for bidding. You can specify minutes, hours or days",
    esTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the time during which the first lot is open for bidding. You can specify minutes, hours or days",
    ptTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the time during which the first lot is open for bidding. You can specify minutes, hours or days",
    enLabel: "Running time for the first lot",
    esLabel: "Tiempo de ejecución para el primer lote",
    ptLabel: "Tempo de execução do primeiro lote",
  },
  "Show bid graph to all participants":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Show bid graph to all participants",
    esTooltip: "Mostrar el gráfico de pujas a todos los participantes",
    ptTooltip: "Mostrar o gráfico de ofertas a todos os participantes",
    enLabel: "Show bid graph to all participants",
    esLabel: "Mostrar el gráfico de pujas a todos los participantes",
    ptLabel:
    "Mostrar o gráfico de ofertas a todos os participantes",
    enOptions: SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_EN,
    esOptions: SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_ES,
    ptOptions: SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_PT,
  },
   "Time between lot closing":{
    type: DATA_TYPE.int,
    allowNull: true,
    isDropdown: false,
    enTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the interval between the closing of each bidding period. You can specify minutes, hours or days",
    esTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the interval between the closing of each bidding period. You can specify minutes, hours or days",
    ptTooltip:
      "This rule is available when you select staggered or serial lot bidding. It is the interval between the closing of each bidding period. You can specify minutes, hours or days",
    enLabel: "Time between lot closing (minutes)",
    esLabel: "Tiempo entre cierres de lotes",
    ptLabel: "Tempo entre o fechamento do lote",
  },
  "Allow Cost Groups to capture detailed Cost Breakdowns":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Allow Cost Groups to capture detailed Cost Breakdowns",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Allow Cost Groups to capture detailed Cost Breakdowns",
    esLabel: "",
    ptLabel:
      "",
    enOptions: ALLOW_COST_GROUPS_TO_CAPTURE_DETAILED_COST_BREAKDOWNS_EN,
    esOptions: ALLOW_COST_GROUPS_TO_CAPTURE_DETAILED_COST_BREAKDOWNS_EN,
    ptOptions: ALLOW_COST_GROUPS_TO_CAPTURE_DETAILED_COST_BREAKDOWNS_EN,
  },
  "Enable approval for team grading":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Enable approval for team grading",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Enable approval for team grading",
    esLabel: "",
    ptLabel:
      "",
    enOptions: ENABLE_APPROVAL_FOR_TEAM_GRADING_EN,
    esOptions: ENABLE_APPROVAL_FOR_TEAM_GRADING_EN,
    ptOptions: ENABLE_APPROVAL_FOR_TEAM_GRADING_EN,
  },
  "Hide winning message from participants":{
    type: DATA_TYPE.string,
    allowNull: false,
    isDropdown: true,
    enTooltip: "Hide winning message from participants",
    esTooltip: "",
    ptTooltip: "",
    enLabel: "Hide winning message from participants",
    esLabel: "Ocultar mensaje de ganador a los participantes",
    ptLabel:
      "Ocultar mensagem vencedora dos participantes",
    enOptions: Hide_winning_message_from_participants_EN,
    esOptions: Hide_winning_message_from_participants_ES,
    ptOptions: Hide_winning_message_from_participants_PT,
  }
};

export default formRenderer;
