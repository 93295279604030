export const DROPDOWN_LANGUAGE_TRANSLATIONS_OBJECT = {
  es: [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Estándar", value: "Standard" },
    { label: "No permitir pujas previas", value: "Do not allow prebids" },
    { label: "Permitir pujas previas", value: "Allow prebids" },
    { label: "Exigir pujas previas", value: "Require prebids" },
    { label: "En serie", value: "Serial" },
    { label: "Escalonado", value: "Staggered" },
    { label: "En paralelo", value: "Parallel" },
    { label: "Sin pujas igualadas", value: "No tie bids" },
    {
      label: "Permitir pujas igualadas para todas las posiciones",
      value: "Allow tie bids for all ranks",
    },
    {
      label: "Sin pujas igualadas para posición 1",
      value: "No tie bids for rank 1",
    },
    {
      label: "Romper el empate entre pujas usando cuándo se enviaron",
      value: "Break tie bids by submit time",
    },
    {
      label: "No activar la restricción inicial",
      value: "Do not enable a starting gate",
    },
    {
      label: "Activar una restricción inicial para cada lote",
      value: "Enable a starting gate for each lot",
    },
    {
      label: "Activar una restricción inicial para todo el evento",
      value: "Enable one starting gate for the entire event",
    },
    {
      label: "Su propia posición cuando están en cabeza",
      value: "Their own rank when leading",
    },
    { label: "Su propia posición", value: "Their own rank" },
    {
      label: "Sí, a compradores y participantes",
      value: "Yes, to Buyers and Participants",
    },
    { label: "Sí, solo a compradores", value: "Yes, to Buyers only" },
    { label: "Importe nominal", value: "Nominal amount" },
    { label: "Porcentaje", value: "Percentage" },
  ],

  pt: [
    { label: "ID", value: "Código" },
    { label: "Value", value: "Valor" },
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
    { label: "Padrão", value: "Standard" },
    { label: "Não permitir pré-ofertas", value: "Do not allow prebids" },
    { label: "Permitir pré-ofertas", value: "Allow prebids" },
    { label: "Exigir pré-ofertas", value: "Require prebids" },
    { label: "Serial", value: "Serial" },
    { label: "Alternado", value: "Staggered" },
    { label: "Paralelo", value: "Parallel" },
    { label: "Sem ofertas empatadas", value: "No tie bids" },
    {
      label: "Permitir ofertas empatadas em todas as classificações",
      value: "Allow tie bids for all ranks",
    },
    {
      label: "Sem ofertas empatadas para classificação 1",
      value: "No tie bids for rank 1",
    },
    {
      label: "Desempatar ofertas por hora de envio",
      value: "Break tie bids by submit time",
    },
    {
      label: "Não ativar Starting Gate",
      value: "Do not enable a starting gate",
    },
    {
      label: "Ativar um Starting Gate para cada lote",
      value: "Enable a starting gate for each lot",
    },
    {
      label: "Ativar um Starting Gate para o evento inteiro",
      value: "Enable one starting gate for the entire event",
    },
    {
      label: "Suas próprias posições quando estiverem liderando",
      value: "Their own rank when leading",
    },
    { label: "Suas próprias posições", value: "Their own rank" },
    {
      label: "Sim, para compradores e participantes",
      value: "Yes, to Buyers and Participants",
    },
    { label: "Sim, somente para compradores", value: "Yes, to Buyers only" },
    { label: "Valor nominal", value: "Nominal amount" },
    { label: "Porcentagem", value: "Percentage" },
  ],
};
