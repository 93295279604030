import React , {useState,useMemo,useContext} from "react";
import {MyContext} from '../../context';
import {
    HTTP_SERVICE_CALL,
    gloabaltoastmessage,
  } from "../../components/ApiProvider/ApiProvider";
import * as Constants from "../../Constants";
import AbevButton from "../../components/AbevButton";
import AbevDropdown from "../../components/Dropdown/Dropdown";
import AbevInput from "../../components/AbevInput";
import formTemplate from "../../data/dutchInputForm.template";
import { DATA_TYPE } from "../../data/constants";
import * as shortUUID from "short-uuid";
import ModelTable from "../../components/ModelTable/ModelTable";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
// import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
// import filterFactory from "react-bootstrap-table2-filter";
import dialogTemplate from "../../data/dialogAddItemInput.template";
import {
    Modal
  } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
const JapaneseReverseAuctionInput= (props) => {
    let history = useHistory();
  const [errorApiEndPoint, seterrorApiEndPoint] = useState("");
  const { setApiDataJapanese,setuserInput,setuniqueAuctionKey_JA} = useContext(MyContext);
    const [AuctionName,setAuctionName]=useState("");
const [Zone,setZone]=useState("");
const [Invited_suppliers,setInvited_suppliers]=useState(0);
const [Baseline_spend,setBaseline_spend]=useState(0);
const [Baseline_currency,setBaseline_currency]=useState("");
const [Historical_spend,setHistorical_spend]=useState(0);
const [tabledata,settabledata]=useState([]);
const [Output_currency,setOutput_currency]=useState("");
const [Item,setItem]=useState([]);
const [Commodity,setCommodity]=useState("");
const [BiddingTime,setBiddingTime]=useState("");
const [Open,setOpen]=useState(false);
const [ItemName,setItemName]=useState("");
const [quantity,setquantity]=useState(0);
const [historic_spend,sethistoric_spend]=useState(0);
const [bfq_spend,setbfq_spend]=useState(0);
const [apiInProgress,setapiInProgress]=useState(false);
const [sellers_nobid,setsellers_nobid]=useState(0);
const [errorMessage,seterrorMessage]=useState({
    AuctionName: "",
        Zone: "",
        Commodity: "",
        Invited_suppliers: "",
        // Bfq: "",
         sellers_nobid: "",
        Baseline_spend: "",
        Baseline_currency: "",
        Historical_spend: "",
        Historical_currency: "",
        Output_currency: "",
});
let ItemObj = {};
let itemsArr = [];
const handleDeleteRow=(index)=>{
  setItem((prevItem) => prevItem.filter((item, i) => i !== index));
}
const Trigger_Columns = [
    {
      dataField: "ItemName",
      text: "Item Name",
    },
    {
      dataField: "quantity",
      text: "Item Quantity",
    },
    {
      dataField: "bfq_spend",
      text: "BFQ Spend",
    },
    // {
    //   dataField: "Bfq_Currency",
    //   text: "BFQ Currency",
    // },
    {
      dataField: "historic_spend",
      text: "Historic Spend",
    },
    {
      dataField: "Status",
      text: "Operation",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            className="btn"
            onClick={() => handleDeleteRow(rowIndex)}
          >
            Delete
          </button>
        );
      },
    }
  ];
  const checkErrorMessage = () => {
    // if (this.state.Bfq == 0) {
    //  Bfq = "NA";
    // }
    // if (Historical_spend == 0) {
    //   setHistorical_spend("NA");
    // }
    // if (sellers_nobid == 0) {
    //  setsellers_nobid(0);
    // }
    if (
      !errorMessage["AuctionName"] &&
      !errorMessage["Zone"] &&
      !errorMessage["Commodity"] &&
      !errorMessage["Invited_suppliers"] &&
      !errorMessage["Baseline_spend"] &&
      !errorMessage["Baseline_currency"] &&
      !errorMessage["Output_currency"] &&
      !errorMessage["sellers_nobid"]
    ) {
      return false;
    } else return true;
  };
   const canSubmit = () =>
     AuctionName &&
     Zone &&
     Invited_suppliers &&
     Baseline_spend &&
     Baseline_currency &&
     Output_currency &&
     Commodity &&
    Item.length>0 &&
    !checkErrorMessage();

    const canItem=()=>AuctionName &&
    Zone &&
    Invited_suppliers &&
    Baseline_spend &&
    Baseline_currency &&
    Output_currency &&
    Commodity &&  !checkErrorMessage();
  const  handleSubmit = async() => {
      let key = shortUUID.generate().slice(-18);
      let auctionName =AuctionName + "-" + key;
      setuniqueAuctionKey_JA(auctionName);
    setapiInProgress(true);
    let params={"auction_name": AuctionName, "zone": Zone, "commodity": Commodity, "output_currency": Output_currency, "invited_suppliers": Invited_suppliers, "baseline_spend": Baseline_spend, "baseline_currency": Baseline_currency,"sellers_nobid": sellers_nobid, "items": Item  , "auctionType": "Japanese",
    // "hashValue": key,
    "Username":props.user,
    "uniqueAuctionKey": auctionName
  };
     setBiddingTime("Enter Bidding time on Ariba");
      try {
        let access = await HTTP_SERVICE_CALL(
          Constants.backendUrl + "get-azure-ml-data/japanese",
          "POST",
          params,
          "",
          );
          setApiDataJapanese(access?.data);
          setuserInput(params);
          history.push("/recommendation");
        if (access && access.status === 200) {
          // setViewAccess(access.data[0].view_access);
          console.log(access.data[0])
         
        } else {
          setapiInProgress(false);
          gloabaltoastmessage( "An Error Occured, Please try after sometime", 5000, "error");
          seterrorMessage(access.message);
        }
    
    } catch (error) {
      setapiInProgress(false);
      gloabaltoastmessage(error?.message, 5000, "error");
        seterrorMessage(error?.message);
        seterrorApiEndPoint(error?.config?.url);
      }
    };
  
//    const handleItemChange = (name) => (e) => {
//       this.setState({ [name]: e.target.value });
//       validChanges(name, parseInt(e.target.value, 10));
//     };

 const handleDialogOnSuccess = (e) => {
  if (historic_spend == 0  || historic_spend == NaN ) {
    ItemObj={"ItemName":ItemName ,"quantity": quantity,"bfq_spend":bfq_spend ,"historic_spend":bfq_spend , "bfq_currency": Output_currency, "historic_currency":Output_currency}
 
   }
   else
  ItemObj={"ItemName":ItemName ,"quantity": quantity,"bfq_spend":bfq_spend ,"historic_spend":historic_spend , "bfq_currency": Output_currency, "historic_currency":Output_currency}
  // itemsArr.push(ItemObj);
   itemsArr = [...Item,ItemObj];
   settabledata(ItemObj);
  setItem(itemsArr);
   setOpen(false);
  setItemName("");
  setquantity(0);
  setbfq_spend(0);
  sethistoric_spend(0);
    };

    let TableMemo = useMemo(() => {
        return   <ModelTable
        keyField={Trigger_Columns[0].dataField}
        data={[Item]}
        columns={Trigger_Columns}
        text="No Item Selected. Please add atleast one item to submit"
      />;
      }, [Item]);

  const handleSelect = (name) => (e) => {
      if(name== "Zone")
    // setZone((prevState) => (prevState[name] = e.value));
    setZone(e.value)
      else {
        // ItemObj = {
        //     ...Item,
        //     [name]:  e.value
        //   }
        ItemObj[name] = e.value;
      }
      if(name== "Commodity")
      setCommodity(e.value);
      else {
        ItemObj[name] = e.value;
      }
    
      if (name === "Baseline_currency") {
        // setOutput_currency(e.value);
        setBaseline_currency(e.value);
        // setHistorical_currency(e.value);
      }
      if (name === "Output_currency") {
         setOutput_currency(e.value);
        // setBaseline_currency(e.value);
        // setHistorical_currency(e.value);
      }
    };
    const checkFormCompletion = () => ItemName && quantity && bfq_spend && !checkError()
    const checkError=()=>{
    if (
      !errorMessage["ItemName"] &&
      !errorMessage["quantity"] &&
      !errorMessage["bfq_spend"] &&
      !errorMessage["historic_spend"]
    ) {
      return false;
    } else return true;
    }
    const handleChange = (name) => (e) => {
      if(name== "Baseline_spend" || name== "historic_spend"  || name== "bfq_spend"){ 
        if(name== "Baseline_spend"){
          // console.log(e.target.value)
          setBaseline_spend(parseFloat(e.target.value));}
    
        if(name=="historic_spend"){
          console.log(e.target.value)
          if(e.target.value=="")
          sethistoric_spend(bfq_spend)
          else
          sethistoric_spend(parseFloat(e.target.value));
         }
         if(name=="bfq_spend"){
          console.log(e.target.value)
           setbfq_spend(parseFloat(e.target.value));
          } 
        validChanges(name,parseFloat( e.target.value,10));
        checkFormCompletion();
      }
      else{
        if(name== "AuctionName"  || name== "Invited_suppliers"  || name== "Historical_spend" || name== "sellers_nobid"
        || name== "ItemName"  || name== "quantity"){
          if(name== "AuctionName"){
            setAuctionName(e.target.value);
        }
        if(name== "Invited_suppliers"){setInvited_suppliers(parseInt(e.target.value));
        }
    
       if(name== "Historical_spend"){ setHistorical_spend(e.target.value);
       }
      
       if(name== "sellers_nobid"){
       setsellers_nobid(parseInt(e.target.value));
       }
      //  if(name!="historic_spend"){
      //   sethistoric_spend(bfq_spend);
      //  }
        if(name=="quantity"){
        setquantity(parseInt(e.target.value));
       }
       if(name=="ItemName"){
        setItemName(e.target.value);
       }
       validChanges(name, parseInt(e.target.value, 10));
       checkFormCompletion();
      }
      
    }
    }
 const validChanges=(name, value) =>{
      let prevError = { ...errorMessage };
      const sw_inital_bid_Value = parseInt(
        sellers_nobid,
        10
      );
      switch (name) {
        case "Invited_suppliers":
          if (value < 1) {
            prevError[name] = "Value cannot be -ve or Zero";
            return seterrorMessage(prevError);
          }
          if (value < sw_inital_bid_Value) {
            prevError[name] =
              "Should be greater than Sellers without initial bid";
              return seterrorMessage(prevError);
          } else {
            prevError[name] = "";
            prevError["sellers_nobid"] = "";
              return seterrorMessage(prevError);
          }
  
        case "sellers_nobid":
          prevError = { ...errorMessage };
  
          if (value >= Invited_suppliers) {
            prevError[name] = "Should be less than Invited Suppliers";
              return seterrorMessage(prevError);
          } else if (value < 0) {
            prevError[name] = "Value should not be -ve";
              return seterrorMessage(prevError);
          } else {
            prevError[name] = "";
              return seterrorMessage(prevError);
          }

        // case "Invited_suppliers":
        //   if (value < 1) {
        //     prevError[name] = "Value cannot be -ve or Zero";
        //     return seterrorMessage(prevError);
        //   } else {
        //     prevError[name] = "";
        //     return seterrorMessage(prevError);
        //   }
        case "Baseline_spend":
          prevError = { ...errorMessage };
          if (value <=0) {
            prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
          } else {
            prevError[name] = "";
            return seterrorMessage(prevError);
          }
  
        case "Historical_spend":
          prevError = { ...errorMessage };
          if (value < 1) {
            prevError[name] = "Value should not be -ve or Zero";
            return seterrorMessage(prevError);
          } else {
            prevError[name] = "";
            return seterrorMessage(prevError);
          }
          case "bfq_spend":
            prevError = { ...errorMessage };
            if (value <=0) {
              prevError[name] = "Value should not be -ve or Zero";
              return seterrorMessage(prevError);
            } else {
              prevError[name] = "";
              return seterrorMessage(prevError);
            }
            case "historic_spend":
              prevError = { ...errorMessage };
              if (value <=0) {
                prevError[name] = "Value should not be -ve or Zero";
                return seterrorMessage(prevError);
              } else {
                prevError[name] = "";
                return seterrorMessage(prevError);
              }
            case "quantity":
              prevError = { ...errorMessage };
              if (value < 1) {
                console.log(value)
                prevError[name] = "Value should not be -ve or Zero";
                return seterrorMessage(prevError);
              }
//               console.log(value)
//               var array=value?.split('');
// if(array.indexOf(".") >= 0){
//   prevError[name] = "Quantity should not be in Decimal";
//    return seterrorMessage(prevError);
// }
              // if (!Number.isInteger(value)) {
              //   console.log(value)
              //   prevError[name] = "Quantity should not be in Decimal";
              //   return seterrorMessage(prevError);
              // } 
              else {
                prevError[name] = "";
                return seterrorMessage(prevError);
              }
        // case "Start_time":
        //   prevError = { ...this.state.errorMessage };
        //   if (value) {
        //     prevError[name] = "";
        //     return this.setState({ errorMessage: prevError });
        //   }
        //   else {
        //     prevError[name] = "Required";
        //     return this.setState({ errorMessage: prevError });
        //   }
        default:
          return true;
      }
    }
return (
    <>
      {/* <Navbar auctionType={"Dutch"} /> */}
      <div className="display-flex">
        <div className="dutch_container"> <h4 className="py-2 y_text">Japanese Reverse Recommendation</h4>
          {Object.keys(formTemplate).map((fieldKey, indx) => {
            let fieldDetails = formTemplate[fieldKey];

            if (
              fieldDetails.type === DATA_TYPE.string &&
              !fieldDetails.isCurrency === true &&
              fieldDetails.isDropdown === false
            ) {
              return (
                <AbevInput
                  key={indx}
                  required={!fieldDetails.allowNull}
                  label={fieldKey}
                  info={fieldDetails.tooltip}
                  handleChange={handleChange(fieldDetails.apiKey)}
                  helperText={errorMessage[fieldDetails.apiKey]}
                />
              );
            }
          })}

          {Object.keys(formTemplate).map((fieldKey, indx) => {
            let fieldDetails = formTemplate[fieldKey];

            if (
              !fieldDetails.isCurrency === true &&
              fieldDetails.isDropdown === true &&
              fieldDetails.apiKey != "Output_currency"
            ) {
              return (
                <AbevDropdown
                  key={indx}
                  required={!fieldDetails.allowNull}
                  label={fieldKey}
                  info={fieldDetails.tooltip}
                  options={fieldDetails.options}
                  value={fieldDetails.apiKey}
                //   defaultValue={{
                //     label: this.state[fieldDetails.apiKey],
                //     value: this.state[fieldDetails.apiKey],
                //   }}
                  handleChange={handleSelect(fieldDetails.apiKey)}
                />
              );
            }
          })}
          {Object.keys(formTemplate).map((fieldKey, indx) => {
            let fieldDetails = formTemplate[fieldKey];

            if (
              fieldDetails.type === DATA_TYPE.int &&
              !fieldDetails.isCurrency === true &&
              fieldDetails.isDropdown === false
            ) {
              return (
                <AbevInput
                  key={indx}
                  required={!fieldDetails.allowNull}
                  label={fieldKey}
                  info={fieldDetails.tooltip}
                  handleChange={handleChange(fieldDetails.apiKey)}
                  helperText={errorMessage[fieldDetails.apiKey]}
                />
              );
            }
          })}
          <div
            // style={{
            //   display: "flex",
            //   flexWrap: "wrap",
            //   alignItems: "flex-end",
            // }}
          >
            {Object.keys(formTemplate).map((fieldKey, indx) => {
              let fieldDetails = formTemplate[fieldKey];

              return (
                <div style={{ display: "flex" }}>
                  {fieldDetails.isCurrency === true &&
                    fieldDetails.isDropdown === true ? (
                    <AbevDropdown
                      key={indx}
                      required={!fieldDetails.allowNull}
                      label={fieldKey}
                      info={fieldDetails.tooltip}
                      options={fieldDetails.options}
                      value={fieldDetails.apiKey}
                    //   defaultValue={{
                    //     label: this.state[fieldDetails.apiKey],

                    //     value: this.state[fieldDetails.apiKey],
                    //   }}
                      handleChange={handleSelect(fieldDetails.apiKey)}
                    />
                  ) : (
                    fieldDetails.isCurrency === true &&
                    fieldDetails.isDropdown === false && (
                      <AbevInput
                        key={indx}
                        label={fieldKey}
                        required={!fieldDetails.allowNull}
                        info={fieldDetails.tooltip}
                        type="number"
                        handleChange={handleChange(fieldDetails.apiKey)}
                        helperText={
                         errorMessage[fieldDetails.apiKey]
                        }
                        text={fieldDetails.text}
                        text1={fieldDetails.text1}
                      />
                    )
                  )}
                </div>
              );
            })}
          </div>
          {Object.keys(formTemplate).map((fieldKey, indx) => {
            let fieldDetails = formTemplate[fieldKey];

            if (
              fieldDetails.isCurrency === false &&
              fieldDetails.isDropdown === true &&
              fieldDetails.apiKey != "Collaboration_Model" &&
              fieldDetails.apiKey != "Commodity" &&
              fieldDetails.apiKey != "Zone"
            ) {
              return (
                <AbevDropdown
                  key={indx}
                  required={!fieldDetails.allowNull}
                  label={fieldKey}
                  info={fieldDetails.tooltip}
                  options={fieldDetails.options}
                  value={fieldDetails.apiKey}
                //   defaultValue={{
                //     label: this.state[fieldDetails.apiKey],

                //     value: this.state[fieldDetails.apiKey],
                //   }}
                  handleChange={handleSelect(fieldDetails.apiKey)}
                />
              );
            }
          })}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {/* <AbevButton
              color="primary"
              variant="contained"
              label="Submit"
              handleClick={handleSubmit}
              disable={!canSubmit()}
            /> */}
            <AbevButton
              color="primary"
              variant="contained"
              label="Add Item"
              handleClick={() => {
                setOpen(true);
            }}
            disable={!canItem()}
            />
          </div>
        {TableMemo}
        <AbevButton
              color="primary"
              variant="contained"
              label="Submit"
              handleClick={handleSubmit}
              disable={!canSubmit()}
            />
        </div>
      </div>
      {/* <Backdrop className="dutch_backdrop" open={this.state.apiInProgress}>
        <div className="dutch_loadingMessage">
          Fetching your recommendations
        </div>
        <CircularProgress />
      </Backdrop> */}
      {apiInProgress ? <Spinner /> : ""}
  <Modal
                        show={Open}
                        dialogClassName="modal-lg schedule-delete-model"
                        className="schedule-delete-model"
                        onHide={() => {
                            setOpen(false);
                        }}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Add Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {Object.keys(dialogTemplate).map((dialogKey, indx) => {
              let fieldDetails = dialogTemplate[dialogKey];
             if( fieldDetails.isCurrency === true &&
              fieldDetails.isDropdown === false ){
                return (<AbevInput
                  key={indx}
                  label={dialogKey}
                  required={!fieldDetails.allowNull}
                  info={fieldDetails.tooltip}
                  type="number"
                  handleChange={handleChange(fieldDetails.apiKey)}
                  helperText={
                   errorMessage[fieldDetails.apiKey]
                  }
                  text={fieldDetails.text}
                  text1={fieldDetails.text1}
                />
              )}
             else if (fieldDetails.isCurrency === false && fieldDetails.isDropdown === false) {
                return (
                  <AbevInput
                    key={indx}
                    required={!fieldDetails.allowNull}
                    label={dialogKey}
                    info={fieldDetails.tooltip}
                    handleChange={handleChange(fieldDetails.apiKey)}
                    helperText={errorMessage[fieldDetails.apiKey]}
                  />
                );
              } else {
                return (
                  <AbevDropdown
                    key={indx}
                    required={!fieldDetails.allowNull}
                    label={dialogKey}
                    info={fieldDetails.tooltip}
                    options={fieldDetails.options}
                    value={fieldDetails.apiKey}
                    // defaultValue={{
                    //   label: this.state[fieldDetails.apiKey],

                    //   value: this.state.Item[fieldDetails.apiKey],
                    // }}
                    handleChange={handleSelect(fieldDetails.apiKey)}
                  />
                );
              }
            })}
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="btn_region" onClick={() => setOpen(false)}>Cancel</button>
                        <button className="btn_region" onClick={handleDialogOnSuccess}
                           disabled={!checkFormCompletion()}
                          >Ok</button>
                        </Modal.Footer>
                      </Modal>

                   
    </>
  
  );
};

export default JapaneseReverseAuctionInput;
