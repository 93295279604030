// URLS SPECIFIC FOR LOCALHOST*/
// export const clientId = "d1dafda4-c088-4106-bd31-e8b6787ff301";
// export const redirectUri = "http://localhost:3000/";
// export const backendUrl = "https://eauctionai-backend-api.azurewebsites.net/";


// Urls for Development Code
// export const clientId = "d1dafda4-c088-4106-bd31-e8b6787ff301";
// export const redirectUri = "https://eauctionai.azurewebsites.net/";
// export const backendUrl = "https://eauctionai-backend-api.azurewebsites.net/";

// Urls for Production Code
export const clientId = "8d17d7ee-a1dd-463b-88da-8671ade2e58e";
export const redirectUri = "https://eauction.ab-inbev.com/";
export const backendUrl = "https://eauctionai-backend-api-prod.azurewebsites.net/";


export const ZONE = [
    { label: "APAC", value: "APAC" },
    { label: "NAZ", value: "NAZ" },
    { label: "SAZ", value: "SAZ" },
    { label: "MAZ", value: "MAZ" },
    { label: "EUR", value: "EUR" },
    { label: "AFR", value: "AFR" },
  ];
  export const  COMMODITY=[
    {label: "CAPEX", value: "CAPEX"},
    {label: "SIMPLE PACKAGES", value: "SIMPLE PACKAGES"},
    {label: "COMMERCIAL", value: "COMMERCIAL"},
    {label: "LOGISTICS", value: "LOGISTICS"},
    {label: "RAU", value: "RAU"},
    {label: "PACKAGING", value: "PACKAGING"},
  ];
  
  export const ADJUST_BID_AMT_OPT = [
    {label: "NOMINAL AMOUNT", value: "NOMINAL AMOUNT"},
    {label: "PERCENTAGE", value: "PERCENTAGE"},
  ]
  
  export const  COLLABORATION=[
    {label: "RPSS", value: "RPSS"},
    {label: "GPO", value: "GPO"},
  ];
  
  export const  CURRENCY=[
    {label:"AED",value:"AED"},
    {label:"ARS",value:"ARS"},
    {label:"AUD",value:"AUD"},
    {label:"BOB",value:"BOB"},
    {label:"BRL",value:"BRL"},
    {label:"BWP",value:"BWP"},
    {label:"CAD",value:"CAD"},
    {label:"CHF",value:"CHF"},
    {label:"CLP",value:"CLP"},
    {label:"CNY",value:"CNY"},
    {label:"COP",value:"COP"},
    {label:"CZK",value:"CZK"},
    {label:"DKK",value:"DKK"},
    {label:"DOP",value:"DOP"},
    {label:"ETB",value:"ETB"},
    {label:"EUR",value:"EUR"},
    {label:"GBP",value:"GBP"},
    {label:"GHC",value:"GHC"},
    {label:"GTQ",value:"GTQ"},
    {label:"HKD",value:"HKD"},
    {label:"HNL",value:"HNL"},
    {label:"HUF",value:"HUF"},
    {label:"ILS",value:"ILS"},
    {label:"INR",value:"INR"},
    {label:"JPY",value:"JPY"},
    {label:"KES",value:"KES"},
    {label:"KRW",value:"KRW"},
    {label:"LSL",value:"LSL"},
    {label:"MUR",value:"MUR"},
    {label:"MWK",value:"MWK"},
    {label:"MXN",value:"MXN"},
    {label:"MZN",value:"MZN"},
    {label:"MYR",value:"MYR"},
    {label:"NAD",value:"NAD"},
    {label:"NGN",value:"NGN"},
    {label:"NOK",value:"NOK"},
    {label:"NZD",value:"NZD"},
    {label:"PEN",value:"PEN"},
    {label:"PLN",value:"PLN"},
    {label:"PYG",value:"PYG"},
    {label:"RUB",value:"RUB"},
    {label:"SDP",value:"SDP"},
    {label:"SEK",value:"SEK"},
    {label:"SGD",value:"SGD"},
    {label:"SZL",value:"SZL"},
    {label:"TRY",value:"TRY"},
    {label:"TZS",value:"TZS"},
    {label:"UAH",value:"UAH"},
    {label:"UGX",value:"UGX"},
    {label:"USD",value:"USD"},
    {label:"UYU",value:"UYU"},
    {label:"VND",value:"VND"},
    {label:"ZAR",value:"ZAR"},
    {label:"ZMK",value:"ZMK"},
  ]
  
  export const DATA_TYPE = {
    string: "string",
    dateTime: "dateTime",
    int: "int",
  };
  
  export const ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ENABLE_PREVIEW_PERIOD_BEFORE_BIDDING_OPENS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_EN = [
    { label: "Do not allow prebids", value: "Do not allow prebids" },
    { label: "Allow prebids", value: "Allow prebids" },
    { label: "Require prebids", value: "Require prebids" },
  ];
  
  export const CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_ES = [
    { label: "No permitir pujas previas", value: "Do not allow prebids" },
    { label: "Permitir pujas previas", value: "Allow prebids" },
    { label: "Exigir pujas previas", value: "Require prebids" },
  ];
  
  export const CAN_PARTICIPANTS_PLACE_BIDS_DURING_REVIEW_PT = [
    { label: "Não permitir pré-ofertas", value: "Do not allow prebids" },
    { label: "Permitir pré-ofertas", value: "Allow prebids" },
    { label: "Exigir pré-ofertas", value: "Require prebids" },
  ];
  
  export const HOW_LOT_BIDDING_BEGIN_AND_END_EN = [
    { label: "Serial", value: "Serial" },
    { label: "Staggered", value: "Staggered" },
    { label: "Parallel", value: "Parallel" },
  ];
  
  export const HOW_LOT_BIDDING_BEGIN_AND_END_ES = [
    { label: "En serie", value: "Serial" },
    { label: "Escalonado", value: "Staggered" },
    { label: "En paralelo", value: "Parallel" },
  ];
  
  export const HOW_LOT_BIDDING_BEGIN_AND_END_PT = [
    { label: "Serial", value: "Serial" },
    { label: "Alternado", value: "Staggered" },
    { label: "Paralelo", value: "Parallel" },
  ];
  
  export const SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SET_REVIEW_PERIOD_AFTER_LOT_CLOSES_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const ALLOW_BIDDING_OVERTIME_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ALLOW_BIDDING_OVERTIME_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ALLOW_BIDDING_OVERTIME_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const CREATE_BUFFER_TO_PROTECT_LEAD_BID_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const CREATE_BUFFER_TO_PROTECT_LEAD_BID_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const CREATE_BUFFER_TO_PROTECT_LEAD_BID_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const PARTICIPANTS_SUBMIT_TIE_BIDS_EN = [
    { label: "No tie bids", value: "No tie bids" },
    {
      label: "Allow tie bids for all ranks",
      value: "Allow tie bids for all ranks",
    },
    { label: "No tie bids for rank 1", value: "No tie bids for rank 1" },
    {
      label: "Break tie bids by submit time",
      value: "Break tie bids by submit time",
    },
  ];
  
  export const PARTICIPANTS_SUBMIT_TIE_BIDS_ES = [
    { label: "Sin pujas igualadas", value: "No tie bids" },
    {
      label: "Permitir pujas igualadas para todas las posiciones",
      value: "Allow tie bids for all ranks",
    },
    {
      label: "Sin pujas igualadas para posición 1",
      value: "No tie bids for rank 1",
    },
    {
      label: "Romper el empate entre pujas usando cuándo se enviaron",
      value: "Break tie bids by submit time",
    },
  ];
  
  export const PARTICIPANTS_SUBMIT_TIE_BIDS_PT = [
    { label: "Sem ofertas empatadas", value: "No tie bids" },
    {
      label: "Permitir ofertas empatadas em todas as classificações",
      value: "Allow tie bids for all ranks",
    },
    {
      label: "Sem ofertas empatadas para classificação 1",
      value: "No tie bids for rank 1",
    },
    {
      label: "Desempatar ofertas por hora de envio",
      value: "Break tie bids by submit time",
    },
  ];
  
  export const ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ALLOW_PARTICIPANTS_TO_SELECTING_BIDDING_CURRENCY_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const MUST_PARTICIPANTS_BID_ON_ALL_ITEMS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const REQUIRE_PARTICIPANT_TO_GIVE_REASON_FOR_DECLINING_BID_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const HOW_PARTICIPANT_VIEW_MARKET_INFO_EN = [
    {
      label: "Do not enable a starting gate",
      value: "Do not enable a starting gate",
    },
    {
      label: "Enable a starting gate for each lot",
      value: "Enable a starting gate for each lot",
    },
    {
      label: "Enable one starting gate for the entire event",
      value: "Enable one starting gate for the entire event",
    },
  ];
  
  export const HOW_PARTICIPANT_VIEW_MARKET_INFO_ES = [
    {
      label: "No activar la restricción inicial",
      value: "Do not enable a starting gate",
    },
    {
      label: "Activar una restricción inicial para cada lote",
      value: "Enable a starting gate for each lot",
    },
    {
      label: "Activar una restricción inicial para todo el evento",
      value: "Enable one starting gate for the entire event",
    },
  ];
  
  export const HOW_PARTICIPANT_VIEW_MARKET_INFO_PT = [
    { label: "Não ativar Starting Gate", value: "Do not enable a starting gate" },
    {
      label: "Ativar um Starting Gate para cada lote",
      value: "Enable a starting gate for each lot",
    },
    {
      label: "Ativar um Starting Gate para o evento inteiro",
      value: "Enable one starting gate for the entire event",
    },
  ];
  
  export const SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_LEAD_BID_TO_ALL_PARTICIPANTS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const PARTICIPANTS_CAN_SEE_RANKS_EN = [
    { label: "No", value: "No" },
    {
      label: "Their own rank when leading",
      value: "Their own rank when leading",
    },
    { label: "Their own rank", value: "Their own rank" },
  ];
  
  export const PARTICIPANTS_CAN_SEE_RANKS_ES = [
    { label: "No", value: "No" },
    {
      label: "Su propia posición cuando están en cabeza",
      value: "Their own rank when leading",
    },
    { label: "Su propia posición", value: "Their own rank" },
  ];
  
  export const PARTICIPANTS_CAN_SEE_RANKS_PT = [
    { label: "Não", value: "No" },
    {
      label: "Suas próprias posições quando estiverem liderando",
      value: "Their own rank when leading",
    },
    { label: "Suas próprias posições", value: "Their own rank" },
  ];
  
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_EN = [
    
    { label: "Yes, to Buyers and Participants", value: "Yes, to Buyers and Participants" },
    { label: "Yes, to Buyers only", value: "Yes, to Buyers only" },
    { label: "No", value: "No" },
  ];
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_EN_NO=[
    { label: "Yes, to Buyers only", value: "Yes, to Buyers only" },
    { label: "No", value: "No" },
  ]
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_ES_NO=[
    { label: "Sí, solo a compradores", value: "Yes, to Buyers only" },

    { label: "No", value: "No" },
  ]
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_PT_NO=[
    { label: "Sim, somente para compradores", value: "Yes, to Buyers only" },

    { label: "Não", value: "No" },
  ]
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_ES = [ 
    {
      label: "Sí, a compradores y participantes",
      value: "Yes, to Buyers and Participants",
    },
    { label: "Sí, solo a compradores",
     value: "Yes, to Buyers only" },
    { label: "No", value: "No" },
  ];
  
  export const LINE_ITEM_LEVEL_RANK_IN_LOT_PT = [  
    {
      label: "Sim, para compradores e participantes",
      value: "Yes, to Buyers and Participants",
    },
    { label: "Sim, somente para compradores",
     value: "Yes, to Buyers only" },
    { label: "Não", value: "No" },
  ];
  
  export const DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const DISPLAY_CALCULATED_VALUE_BEFORE_SUBMIT_BID_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const SHOW_FORMULAS_TO_ALL_PARTICIPANTS_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_FORMULAS_TO_ALL_PARTICIPANTS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_FORMULAS_TO_ALL_PARTICIPANTS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SPECIFY_PARTICIPANT_SPECIFIC_INITIAL_VALUE_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const CAN_OWNER_SEE_RESPONSE_BEFORE_EVENT_CLOSE_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const ENABLE_TRAFFIC_LIGHT_BIDDING_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ENABLE_TRAFFIC_LIGHT_BIDDING_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const ENABLE_TRAFFIC_LIGHT_BIDDING_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const IMPROVE_BID_AMOUNT_BY_EN = [
    { label: "Nominal amount", value: "Nominal amount" },
    { label: "Percentage", value: "Percentage" },
  ];
  
  export const IMPROVE_BID_AMOUNT_BY_ES = [
    { label: "Importe nominal", value: "Nominal amount" },
    { label: "Porcentaje", value: "Percentage" },
  ];
  
  export const IMPROVE_BID_AMOUNT_BY_PT = [
    { label: "Valor nominal", value: "Nominal amount" },
    { label: "Porcentagem", value: "Percentage" },
  ];
  
  export const SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_PARTICIPANTS_TO_OTHER_PARTICIPANTS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  
  export const LANGUAGE_CONST = [
    { label: "EN - English", value: "en" },
    { label: "ES - Spanish", value: "es" },
    { label: "PT - Portuguese", value: "pt" },
  ];
  
  export const RECOMMENDATION_HEADERS = {
    2: "Timing Rules",
    14: "Bidding Rules",
    20: "Market Feedback",
    29: "Currency Rules",
  };
  
  // Dutch

  export const ADJUST_BID_AMOUNT_BY_EN=[
    {label: "Nominal Amount", value: "Nominal amount"},
    {label: "Percentage", value: "Percentage"},
  ];
  export const ADJUST_BID_AMOUNT_BY_ES = [
    { label: "Importe nominal", value: "Nominal amount" },
    { label: "Porcentaje", value: "Percentage" },
  ];
  
  export const ADJUST_BID_AMOUNT_BY_PT = [
    { label: "Valor nominal", value: "Nominal amount" },
    { label: "Porcentagem", value: "Percentage" },
  ];

  export const HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_EN=[ { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },];
  export const HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const HIDE_COUNTDOWN_CLOCK_FROM_PARTICIPANTS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];

  export const REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_EN=[
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]
  export const REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const REQUIRE_PARTICIPANT_TO_GIVE_A_REASON_FOR_DECLINING_TO_BID_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];

   export const SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_EN=[
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },

   ];
   export const SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const SHOW_BID_GRAPH_TO_ALL_PARTICIPANTS_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  //  Allow Cost Groups to capture detailed Cost Breakdowns,Enable approval for team grading
  export const ALLOW_COST_GROUPS_TO_CAPTURE_DETAILED_COST_BREAKDOWNS_EN=[
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
   ];
  
   export const ENABLE_APPROVAL_FOR_TEAM_GRADING_EN=[
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
   ];

   export const Hide_winning_message_from_participants_EN = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  export const Hide_winning_message_from_participants_ES = [
    { label: "Sí", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const Hide_winning_message_from_participants_PT = [
    { label: "Sim", value: "Yes" },
    { label: "Não", value: "No" },
  ];
  export const Trigger_Columns_Table = [
    {
      dataField: "ItemName",
      text: "Item Name",
    },
    {
      dataField: "quantity",
      text: "Item Quantity",
    },
    {
      dataField: "bfq_spend",
      text: "Item BFQ Spend",
    },  
    {
      dataField: "extended_price",
      text: "Extended BFQ Spend",
    },
    {
      dataField: "historic_spend",
      text: "Item Historic Spend",
    },
    {
      dataField: "Item initial price",
      text: "Item Initial Price",
    }, {
      dataField: "Bid adjustment",
      text: "Bid Adjustment",
    },
    {
      dataField: "bfq_currency",
      text: "BFQ / Historic Spend Currency",
    },
   
     

  ];