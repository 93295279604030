import React from "react";
import Select from "react-select";
import Title from "../Title/Title";
export default function AbevDropdown({
  options,
  defaultValue,
  label,
  handleChange,
  info,
  required,
  value,
  style,
  ...props
}) {
 
 
  return (
    <div className="dropdown_container">
      <div className="labelContainer" >
      <label >{label}</label>
        <span className="asterix">{required ? "*" : ""}</span>
        {info && (
          <div className="info">
            <Title text={info} />
          </div>
        )}
      </div>
      <Select
        className="select"
        options={options}
        label={label}
        value={defaultValue}
        onChange={handleChange}
        
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          spacing: {
            baseUnit: 5,
            controlHeight: 45,
          },
          colors: {
            ...theme.colors,
            primary: "#f5e003",
            primary25: "#e5b611",
          },
        })}
      />
    </div>
  );
}
